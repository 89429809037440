
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

const getFullName = (detail)  => {
  return detail ? `${detail.honorificPrefix ? `${detail.honorificPrefix} ` : ''}${detail.firstname} ${detail.lastname}${detail.honorificSuffix ? `, ${detail.honorificSuffix}` : ''}` : null
}
let notes = (rec) =>  {
      if (rec.notes) {
        let notes = rec.notes
        if (typeof notes === 'string') {
          notes = notes.trim()
          notes = notes.split("\n")
        }
          return (
          <div>
              {notes.map((note, index) => {
                return (<div className='text-1'>{`${index + 1}. `} {note}</div>)
              })}
          </div>
          )
      }
      else {
          return ('NA');
      }
}

let active = (rec) => {
  if (rec.endedAt && moment(Date()).format("YYYY-MM-DD") > moment(rec.endedAt).format("YYYY-MM-DD")) {
    return false
  }
  else {
    return true
  }
}

function MedicationListTemplate(props) {
  const [aspectRatio, setAspectRatio] = useState(null);
  const [medications, setMedications] = useState(null);
  useEffect(() => {
    if(props.medications){
      setMedications(props.medications.filter(med => active(med)))
    }
    const calculateAspectRatio = (siteData) => {
      return new Promise((resolve, reject) => {
        const src = siteData ? siteData.bannerLogoFile ? `data:${siteData.bannerLogoContentType};base64,${siteData.bannerLogoFile}`
          : (siteData.logoFile ? `data:${siteData.logoContentType};base64,${siteData.logoFile}` : '') : '';
        const img = new Image();
        img.src = src;

        img.onload = () => {
          const aspectRatio = img.naturalWidth / img.naturalHeight;
          resolve(aspectRatio);
        };

        img.onerror = (err) => {
          reject(err);
        };
      });
    }
    if (props.siteData) {
      calculateAspectRatio(props.siteData).then((ratio) => {
        setAspectRatio(ratio);
      }).catch((err) => {
        setAspectRatio(null);
      });
    }
  }, [props.medications]);
  return (
    medications ?
    <>
      <div className='responsive-div' id='medicationList'>
        <div className="col-12 d-flex">
          <div className="logo">
              {props.siteData ? <img id='site-logo-top-nav' 
                    style={{ width: aspectRatio ? `${aspectRatio * (props.siteData.name.toLowerCase() == 'viavi' ? 20 : 54)}px` : '' }} 
                      className={`${props.siteData.name.toLowerCase() == 'viavi' ? 'viavi-theme_nav-logo' : 'nav-logo'}`} 
                    src={ props.siteData.bannerLogoFile
                      ? `data:${props.siteData.bannerLogoContentType};base64,${props.siteData.bannerLogoFile}`
                      : (props.siteData.logoFile ? `data:${props.siteData.logoContentType};base64,${props.siteData.logoFile}` :  props.recommendationSiteLogo )} /> : ''}
          </div>
          <div className="heading-div">
            <div className="provider-name">
              {getFullName(props.presentPatient)}
            </div>
            <div className="heading-1">
              {`Medication List as of ${props.lastVisitDate}`}
            </div>
          </div>
        </div>
        <div>
            <table class="table table-1">
                  <tr><th>Time</th> <th>Medication</th><th>Instructions</th><th>Notes</th></tr>
            </table>
            { medications.filter(med => med.isMorningRecomendedTime).length ? 
            <div class="section">
                <table class="table table-2" >
                    <tr><th style={{verticalAlign: 'middle'}}>AM</th>
                        <td className='p-0'>
                            <table class="table table-3" >
                                <tbody>
                                  {
                                    medications.filter(med => med.isMorningRecomendedTime).map((rec, index) =>{
                                      return(
                                        <tr>
                                          <td>{`${index + 1}. `} {rec.body + " " + ( rec.body ? rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "" : "")} </td>
                                          <td>{rec.instructions ? rec.instructions : "NA"}</td>
                                          <td>{notes(rec)}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            : ""}
            { medications.filter(med => med.isMidDayRecomendedTime).length ?
              <div class="section">
                  <table class="table table-2" >
                      <tr><th style={{overflowWrap: 'anywhere'}}>MID DAY</th>
                          <td className='p-0' >
                              <table class="table table-3" >
                                  <tbody>
                                    {
                                      medications.filter(med => med.isMidDayRecomendedTime).map((rec, index) =>{
                                        return(
                                          <tr>
                                            <td>{`${index + 1}. `} {rec.body + " " + ( rec.body ? rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "" : "")} </td>
                                            <td>{rec.instructions ? rec.instructions : "NA"}</td>
                                            <td>{notes(rec)}</td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </table>
              </div>
            : ""}
            { medications.filter(med => med.isNightRecomendedTime).length ?
            <div class="section">
                <table class="table table-2" >
                    <tr><th style={{verticalAlign: 'middle'}}>PM</th>
                        <td className='p-0'>
                            <table class="table table-3" >
                                <tbody>
                                  {
                                    medications.filter(med => med.isNightRecomendedTime).map((rec, index) =>{
                                      return(
                                        <tr>
                                          <td>{`${index + 1}. `} {rec.body + " " + ( rec.body ? rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "" : "")} </td>
                                          <td>{rec.instructions ? rec.instructions : "NA"}</td>
                                          <td>{notes(rec)}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
            : ""}
            { medications.filter(med => !med.isNightRecomendedTime && !med.isMidDayRecomendedTime && !med.isMorningRecomendedTime).length ?
              <div class="section">
                  <table class="table table-2" >
                      <tr><th style={{verticalAlign: 'middle'}}>Other</th>
                          <td className='p-0'>
                              <table class="table table-3" >
                                  <tbody>
                                    {
                                      medications.filter(med => !med.isNightRecomendedTime && !med.isMidDayRecomendedTime && !med.isMorningRecomendedTime).map((rec, index) =>{
                                        return(
                                          <tr>
                                            <td>{`${index + 1}. `} {rec.body + " " + ( rec.body ? rec.body.includes(rec.strength) ? "" : rec.strength ? rec.strength : "" : "")} </td>
                                            <td>{rec.instructions ? rec.instructions : "NA"}</td>
                                            <td>{notes(rec)}</td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                              </table>
                          </td>
                      </tr>
                  </table>
              </div>
            : ""}
        </div>
      </div> 
    </> 
    : ""
  );
};

export default connect()(MedicationListTemplate);



