import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CategoryComponent from '../containers/CategoryContainer';
import RecommendationList from '../containers/RecommendationListContainer';
import BiomarkerAgingList from '../containers/BiomarkerAgingListContainer';
import TreatmentPlan from '../containers/TreatmentPlanContainer';
import labels from '../../../config/localization';
import constants from '../../../config/constants';
import datumChangeCalculation from '../tools/datumChangeCalculation';
import { setSitePreference } from '../tools/calendar';
import Loader from '../../../components/Loader';
import { formatDateWithMoment } from '../tools/helpers';
import HistoricDataChart from '../containers/HistoricDataChartContainer';
import ReportCardChart from '../components/ReportCardChart';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BarChart from './BarChart';
import { round } from '../modules/analyticsBeta';
import UpwardArrow from '../../../assets/images/arrow_upward.svg';
import DownwardArrow from '../../../assets/images/arrow_downward.svg';


function DashboardComponent(props) {
    useEffect(() => {
        let element = document.getElementsByClassName("report-card-text")
        element && element[0] ? element[0].scrollIntoView(0,0) : ""
        props.setRecommendationType("active")
        props.setDrawerType(null)
        props.resetRecommendationFilter();
        props.toggleCategoryDetailsModal(false)
        // componentDidMount //
        return () => {
            // componentWillUnmount() //
        }
    }, [])
    const [isLineChart, setToLineChart] = useState(false);
    let categories = props.categories ? props.categories : [];
    let _calculator = "";
    let baseline_calculator = "";
    let previous_calculator = "";
    
    // Site Specific GPA - PMI-3281
    if (categories && props.grade_historic_data && props.siteData.noumenonCodes.length > 0) {
        baseline_calculator = new datumChangeCalculation({ historicData: props.siteSpecificLineGraphData ? props.siteSpecificLineGraphData.sort((a, b) => new Date(a.visitDate) - new Date(b.visitDate)) : [], value: props.siteSpecificLineGraphData && _.last(props.siteSpecificLineGraphData) ? _.last(props.siteSpecificLineGraphData).value : 0}, "baseline");
        previous_calculator = new datumChangeCalculation({ historicData: props.siteSpecificLineGraphData ? props.siteSpecificLineGraphData.sort((a, b) => new Date(a.visitDate) - new Date(b.visitDate)) : [], value: props.siteSpecificLineGraphData && _.last(props.siteSpecificLineGraphData) ? _.last(props.siteSpecificLineGraphData).value : 0}, "previous");
    }
    else{
        if (categories && props.grade_historic_data) {
            baseline_calculator = new datumChangeCalculation(props.grade_historic_data, "baseline");
            previous_calculator = new datumChangeCalculation(props.grade_historic_data, "previous");
        }
    }

    let formattedAbsoluteChanged = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.absoluteChange != null)
            return parseFloat(round(_calculator.absoluteChange, 2));
        else
            return (null);
    }
    let formattedPercentChanged = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.percentChange != null)
            return ("(" + _calculator.percentChange + " %)");
        else
            return (null);
    }
    let findDirection = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        if (_calculator.direction != null) {
            if (_calculator.direction == "up") {
                return (<img src={UpwardArrow} alt='Up Arrow'/>)
            }
            else if (_calculator.direction == "down")
                return (<img src={DownwardArrow} alt='Down Arrow'/>)
            else
                return (null);
        }
        else
            return (null);
    }

    let previousValue = () => {
        return (_calculator.previous_value);
    }

    let previousDate = (change_from) => {
        if (change_from == "baseline") {
            _calculator = baseline_calculator
        }
        else {
            _calculator = previous_calculator
        }
        return (_calculator.previous_date);
    }
    let renderHistoricData = (props) => {
        if (props.grading_data && props.grading_data.length) {
            let nonBlankValue = item => !!item.value
            // Site Specific GPA - PMI-3281
            let historicNonBlankData = props.siteData.noumenonCodes.length > 0 ? props.siteSpecificLineGraphData : props.grading_data ? props.grading_data.filter(nonBlankValue) : '';
            if ( historicNonBlankData && historicNonBlankData.length >= 1) {
                return (
                    <div className='chart-content'>
                        <HistoricDataChart historic_data={historicNonBlankData} drawerType='reportCard' type='pointed' {...props} page = 'dashboard'/>
                    </div>
                );
            }
            else{
                historicNonBlankData = [
                    {
                        "value": "0.00",
                        "label": "report-grades",
                        "points": "0.00",
                        "color": "#37b45e",
                        "visitDate": "0"
                    }
                ]
                return (
                    <div className='chart-content'>
                        <HistoricDataChart historic_data={historicNonBlankData} drawerType='reportCard' type='pointed' {...props} page = 'dashboard'/>
                    </div>
                );
            }
        }
    }

    let getPhysioAgeColor = (props) => {
        let physioAge = props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 ? _.last(props.historic_data_chart['physioAges']) ? _.last(props.historic_data_chart['physioAges']).value : '' : '';
        if(parseFloat(physioAge) > parseFloat(props.age_round)){
            return '#B33305';
        }
        else if(parseFloat(physioAge) < parseFloat(props.age_round)){
            return '#1D9C04';
        }
        else{
            return '#3397C9';
        }
    }
    return (
        <>
            {props.categories ?
                <div className='d-flex row m-0 dashboard dashboard-container no-gutters'>
                    <div className="col-lg-6 padding col-md-6 col-sm-12">
                        <div className="dashboard-report-card">
                            <div className={`report-card-section ${props.siteData && props.siteData.noumenonCodes && props.siteSpecificLineGraphData ? '' : props.siteData && props.siteData.noumenonCodes.length > 0 ? 'site-specific-empty-data' : ''}`}>
                                <div className='row change-from-past-dashboard'>
                                    <div className='col'>
                                        <div className="report-card-text">
                                            {props.terms['Report Card']}
                                        </div>
                                        <div className='report-date-text'>
                                            as of {props.lastVisitDate}
                                        </div>
                                    </div>
                                    {formattedAbsoluteChanged("previous") && formattedPercentChanged("previous") ?
                                        <div className='col padding-left-50'>
                                            <div className='pointer'>
                                                {formattedAbsoluteChanged("previous")} {formattedPercentChanged("previous")}
                                                <span className='ml-2'>
                                                    {findDirection("previous")}
                                                </span>
                                            </div>
                                            <div className='changed-text'>
                                                {labels.analytics_labels.change_from} {labels.analytics_labels.last_visit_label}
                                            </div>
                                            <div className='date-text'>
                                                {setSitePreference(props.siteData.datePreference, previousDate("previous"))}
                                            </div>
                                        </div>
                                        : ""}
                                    {formattedAbsoluteChanged("baseline") && formattedPercentChanged("baseline") ?
                                        <div className='col'>
                                            <div className='pointer'>
                                                {formattedAbsoluteChanged("baseline")} {formattedPercentChanged("baseline")}
                                                <span className='ml-2'>
                                                    {findDirection("baseline")}
                                                </span>
                                            </div>
                                            <div className='changed-text'>
                                                {labels.analytics_labels.change_from} {labels.analytics_labels.baseline_label}
                                            </div>
                                            <div className='date-text'>
                                                {'since ' + setSitePreference(props.siteData.datePreference, previousDate('baseline'))}
                                            </div>
                                        </div>
                                        : ""}

                                </div>
                                <div className='dashboard-charts-main-div row no-gutters'>
                                    <div className='graphs-analytics-dashboard width-100 col-lg-3 col-12 graph-analytics-div-1024 align-items-center justify-content-center'>
                                        <div className='circular-gauge'>
                                            <ReportCardChart {...props} />
                                        </div>
                                        {props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 && _.last(props.historic_data_chart['physioAges']) ?
                                        <div className='age-bars-container align-items-center'>
                                            <BarChart text = 'Real Age' age = {`${props.age_round ? props.age_round : props.age}`} fillColor = {`${props.theme == "light-theme" ?  `var(--real-age-bar-c)` : `var(--real-age-bar-c-d)`}`} theme = {props.theme} textColor = {`${props.theme == "light-theme" ?  `var(--real-age-bar-c)` : `var(--real-age-bar-c-d)`}`}/>
                                            <BarChart text = 'PhysioAge' age = {`${props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 ? _.last(props.historic_data_chart['physioAges']) ? _.last(props.historic_data_chart['physioAges']).value : '' : ''}`} fillColor = {`${getPhysioAgeColor(props)}`} theme = {props.theme} textColor = {`${getPhysioAgeColor(props)}`}/>
                                        </div>
                                        : ''}
                                    </div>
                                    <div className='gpa-chart-dashboard width-100 col-lg-9 col-12 mt-3 justify-content-center gpa-chart-dashboard-1024'>
                                        <div id='drawer-body'>
                                            {renderHistoricData(props)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {props.categories ? <CategoryComponent {...props} /> : ""}
                        </div>
                    </div>
                    <div className="col-lg-6 padding col-md-6 col-sm-12">
                        <div className='treatment-biomarker-container'>
                            <div className={'treatment-plan' + (props.historic_data_chart
                                && props.historic_data_chart.biomarkerCount == 0 ? ' no-biomarkers' : '')}>
                                <RecommendationList  {...props} />
                            </div>
                            {props.historic_data_chart && props.historic_data_chart.biomarkerCount > 0 ?
                                <div className='biomarkers-of-Aging-plan'>
                                    <BiomarkerAgingList {...props} />
                                </div> : ''}
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
}
export default connect()(DashboardComponent);