import axios from '../../../lib/axios';
import API_URL from '../../../config/api';
import constants from '../../../config/constants';
import { toggleNotification, handleErrors, setLoggedInUser, toggleLoading, logOut, handleLoading } from '../../../reducers/global-reducer';
import messages from '../../../config/messages';
import labels from '../../../config/localization';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import resolveSearchFilters from '../../../utils/resolve-search-params';
import en_labels from '../../../config/en_labels';
import { downloadExcel, downloadExcelImproved } from '../../../utils/download-utils';

const SET_PROPS = 'SET_PROPS';
const CLEAR_USERS = 'CLEAR_USERS';

const reqFields = {
    add: {
        admin: ['firstName', 'lastName', 'email', 'role', 'password', 'confirmPassword'],
        caregiver: ['firstName', 'lastName', 'email', 'role', 'primarySiteId'],
        patient: ['firstName', 'lastName', 'email', 'role', 'password', 'primarySiteId', 'confirmPassword']
    },
    edit: {
        admin: ['firstName', 'lastName', 'role', 'email'],
        caregiver: ['firstName', 'lastName', 'email', 'role', 'primarySiteId'],
        patient: ['firstName', 'lastName', 'email', 'role', 'primarySiteId']
    }
}
const reqFieldText = {
    firstName: labels.user_labels.firstname,
    lastName: labels.user_labels.lastname,
    role: labels.user_labels.role,
    email: labels.user_labels.email,
    primarySiteId: labels.user_labels.site,
    password: labels.user_labels.password,
    confirmPassword: labels.user_labels.confirm_password
}

const userDetails = {
    originalRole: en_labels.user_labels.role,
    site: en_labels.user_labels.site_name,
    name: en_labels.user_labels.user_name,
    email: en_labels.user_labels.email,
}

// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }
export function getUsers(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentRole = getState().global.loggedInUser.role
        // if (currentRole == constants.logged_roles.AN) {
        //     promisesList.push(axios.get(API_URL.USERS_LIST).then((response) => {
        //         dispatch({
        //             type: SET_PROPS,
        //             payload: {
        //                 users: response.data,
        //                 allMails: getUserEmails(response.data),
        //                 no_grid_data: response.data && !response.data.length ? true : false
        //             }
        //         })
        //         dispatch(handleLoading())
        //     }).catch(error => {
        //         dispatch(handleErrors(error))
        //     }))
        // }
        // else if (currentRole == constants.logged_roles.CG) {
        if (!getState().users.searchParam || !getState().users.searchParam.searchFilters) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    searchParam: {
                        searchFilters: []
                    }
                }
            })
        }
        let searchParam = _.cloneDeep(getState().users.searchParam);
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })
        if (history)
            setSearchParamUrl(searchParam, currentSearchFilters, history)

        searchParam.searchFilters = searchParam.searchFilters.filter(u => u.fieldValue != 'all')

        if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == "role").length) {
            let providerFlag = "-1";
            searchParam.searchFilters.map(sf => {
                if (sf.fieldName == "role" && sf.fieldValue == "caregiver") { providerFlag = "0" }
                else if (sf.fieldName == "role" && sf.fieldValue == "provider") { providerFlag = "1" }
            })

            if (providerFlag == "1" || providerFlag == "0") {
                if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == "isprovider").length) {
                    searchParam.searchFilters.map(sf => {
                        if (sf.fieldName == "isprovider") { sf.fieldValue = providerFlag }
                    })
                } else {
                    searchParam.searchFilters.push({
                        fieldName: "isprovider",
                        fieldValue: providerFlag
                    })
                }
                if (providerFlag == "1") {
                    searchParam.searchFilters.map(sf => {
                        if (sf.fieldName == "role") { sf.fieldValue = "caregiver" }
                    })
                }
            }
            else {
                searchParam.searchFilters = searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() != "isprovider");
            }
        }

        promisesList.push(axios.post(API_URL.USERS_SEARCH_URL, searchParam).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    users: response.data,
                    filteredUsers: response.data,
                    allMails: getUserEmails(response.data),
                    no_grid_data: response.data && !response.data.length ? true : false,
                }
            })
            dispatch(filterUsers(getState().users.searchFieldName || ''))
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
        // }
    }
}

export function filterUsers(searchFieldName) {
    return (dispatch, getState) => {
        let filteredUsers = [...getState().users.users]

        if (searchFieldName) {
            let search = searchFieldName.toLowerCase()
            filteredUsers = filteredUsers.filter(u => u.email.toLowerCase().indexOf(search) > -1 || u.name.toLowerCase().indexOf(search) > -1)
        }
        let userEmails = getUserEmails(filteredUsers)
        dispatch({
            type: SET_PROPS,
            payload: {
                searchFieldName: searchFieldName.toLowerCase(),
                filteredUsers,
                allMails: userEmails
            }
        })
    }
}

export function setSearchParam(val, key, search, history) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let searchParam = Object.assign({}, getState().users.searchParam)

        if (searchParam.searchFilters) {
            if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
                searchParam.searchFilters.map(sf => {
                    if (sf.fieldName == key) { sf.fieldValue = val }
                })
            } else {
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
        } else {
            searchParam.searchFilters = []
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: searchParam,
                currentSearchFilters
            }
        })
        if (search)
            dispatch(getUsers(history))
    }
}

export function clearSearch(urlparams, history) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: {
                    searchFilters: []
                },
                currentSearchFilters: {},
                searchFieldName: ''
            }
        })
        if (urlparams && Object.keys(urlparams).length) {
            Object.keys(urlparams).map(p => dispatch(setSearchParam(urlparams[p], p)))
        }
        dispatch(getUsers())
        dispatch(toggleNotification(false))
    }
}

function setSearchParamUrl(searchParam, currentSearchFilters, history) {
    if (currentSearchFilters.siteId && !parseInt(currentSearchFilters.siteId))
        currentSearchFilters.siteId = ''
    let searchParamUrl = '', params = ''
    let filters = Object.keys(currentSearchFilters).filter(csf => currentSearchFilters[csf]).map(csf => `${csf}=${currentSearchFilters[csf]}`)
    searchParamUrl = filters.join('&')
    let paramFilters = Object.keys(searchParam).filter(csf => searchParam[csf])
        .map(csf => {
            if (csf != 'searchFilters')
                return `${csf}=${searchParam[csf]}`
        })
    params = _.compact(paramFilters).join('&')
    if (searchParamUrl) {
        searchParamUrl = (`?${searchParamUrl}${params}`)
    } else {
        searchParamUrl = (`?${params}`)
    }
    return history.push(searchParamUrl)
}

function getSites() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sites: response.data ? response.data : []
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))

        }))
    }
}
function getUserEmails(users) {
    let userEmails = []
    if (users) {
        users.map(user => {
            if (constants.show_email_roles.indexOf(user.role) != -1) {
                userEmails.push(user.email)
            }
        })
    }
    return userEmails.length ? userEmails.join('; ') : ''
}
export function naviagateEditUser(history, path) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: {}
            }
        })
        history.push(path)
    }
}
//ToDo: handle account settings
export function setCurrentUser(userId) {
    return (dispatch, getState) => {
        let userParam = userId ? userId : getState().global && getState().global.loggedInUser
            && getState().global.loggedInUser.id ? getState().global.loggedInUser.id : null
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(userParam ? (`${API_URL.USERS_URL}?id=${userParam}&includeProvider=true`) : (API_URL.USERS_URL + '?includeProvider=true')).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentUser: { ...response.data, originalPrimarySiteId: response.data.primarySiteId},
                    uneditUser: response.data,
                    primaryContact: response.data.isPrimaryContact
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            if (error.response.status == 403) {
                getState().users.history.push('/statuscode/403')
            }
            else {
                dispatch(handleErrors(error))
            }
        }))
    }
}
export function addUser(history) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: { active: 1, isProvider: 0, isPrimaryContact: 0 },
                primaryContact: 0
            }
        })
        history.push('/users/new')
    }
}
export function addUserSites(history, sites) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: { primarySiteId: sites.id }
            }
        })
        history.push('/users/new')
    }
}
function editUser(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let key = eve.target.id
        let value = eve.target.value
        let currentUser = getState() && getState().users && getState().users.currentUser ? getState().users.currentUser : {};
        
        //If role is provider then isProvider should be 1 else it should be 0. Here isProvider = 1 identifies the user as provider
        if (key == "role" && value == "provider")
            currentUser = { ...currentUser, "isProvider": 1 };
        if (key == "role" && value != "provider")
            currentUser = { ...currentUser, "isProvider": 0 };

        if (eve.target.type == 'checkbox') {
            value = eve.target.checked ? 1 : 0
        }
        currentUser = { ...currentUser, [key]: value }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser,
                valueOfRole: value,
                showMessage: false
            }
        })

    }
}

function editPassword(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentUser = getState() && getState().users && getState().users.currentUser
            ? { ...getState().users.currentUser, [eve.target.id]: eve.target.value } : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: currentUser,
                isPasswordMatched: (currentUser.password && currentUser.confirmPassword) ? currentUser.password == currentUser.confirmPassword : true
            }
        })
    }
}
function saveUser(history, mode) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                showMessage: true
            }
        })
        var loggedInUser = getState() && getState().global.loggedInUser ? getState().global.loggedInUser : {}
        let currentUser = getState() && getState().users && getState().users.currentUser ? getState().users.currentUser : {}
       
        if (currentUser.originalPrimarySiteId && currentUser.primarySiteId && currentUser.originalPrimarySiteId != currentUser.primarySiteId) {
            currentUser['siteIds'].pop(currentUser.originalPrimarySiteId)
            currentUser['siteIds'].push(parseInt(currentUser.primarySiteId))
        }
        else if (!currentUser.originalPrimarySiteId && currentUser.primarySiteId ) {
            currentUser['siteIds'] = [parseInt(currentUser.primarySiteId)]
        }
        
        if (loggedInUser.role == constants.logged_roles.CG) {
            if (!currentUser.role) {
                currentUser['role'] = loggedInUser.role
            }
        }
        //For the authorization purpose we send the role = "caregiver" and isProvider = 1 to mention user as provider instead of role = "provider"
        if (currentUser.role == "provider")
            currentUser.role = constants.logged_roles.CG

        if (currentUser.role == constants.logged_roles.AN) {
            currentUser.can_remove_patients = 1;
            currentUser.isPrimaryContact = 0;
            currentUser.isProvider = 0;
        }

        else if (currentUser.role == constants.logged_roles.CG)
            currentUser.can_remove_patients = currentUser.can_remove_patients ? 1 : null
        else
            currentUser.can_remove_patients = null

        if (currentUser.honorificPrefix == '0')
            delete currentUser.honorificPrefix

        if (currentUser.role != constants.logged_roles.CG && currentUser.avatarFileName) {
            delete currentUser.avatarContent
            delete currentUser.avatarContentType
            delete currentUser.avatarFileSize
            delete currentUser.avatarFileName
        }

        let req = currentUser && currentUser.role && currentUser.role != '0'
            ? reqFields[mode][currentUser.role].filter(rf => (!currentUser[rf] || parseInt(currentUser[rf]) == 0))
            : reqFields[mode][constants.logged_roles.CG].filter(rf => (!currentUser[rf] || parseInt(currentUser[rf]) == 0))
        if ((req && req.length)) {
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                req.map(r => `${reqFieldText[r]} ${labels.required_label}`)
                , false))
        } else if ((currentUser.password != currentUser.confirmPassword) && mode == constants.add) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    isPasswordMatched: false
                }
            })
        }
        else if (!req.length && (getState().users.isPasswordMatched || mode == constants.edit)) {
            if (mode == constants.edit) {
                currentUser = Object.assign({}, currentUser, {
                    password: getState().users.uneditUser.password,
                    confirmPassword: getState().users.uneditUser.confirmPassword
                })
            }
            dispatch(toggleLoading(true))
            promisesList.push(axios[currentUser.id ? 'put' : 'post'](API_URL.USERS_URL, { ...currentUser }).then(response => {

                if (currentUser.id == loggedInUser.id && !currentUser.active) {
                    dispatch(logOut(history))
                } else {
                    dispatch(setLoggedInUser())
                    setTimeout(() => {
                        dispatch(handleLoading())
                        let userDetailsPath = history.userDetailsPath ?  history.userDetailsPath : null
                        if (userDetailsPath){
                            delete history.userDetailsPath
                            history.push(userDetailsPath)
                        }
                        else if (currentUser.id == loggedInUser.id && loggedInUser.role == constants.logged_roles.AN) {
                            if (currentUser.role == constants.logged_roles.CG) {
                                history.push('/patients/list')
                            } else {
                                history.push('/users/list')
                            }
                        }
                        else if (loggedInUser.role == constants.logged_roles.CG) {
                            history.push('/users/list')
                        } else {
                            history.push('/users/list')
                        }
                        if (mode == constants.add) {
                            dispatch(toggleNotification(true, constants.notification_type.success, '',
                                [messages.user_add_success]
                                , false))
                        } else {
                            dispatch(toggleNotification(true, constants.notification_type.success, '',
                                [messages.user_update_success]
                                , false, 5000))
                        }
                    }, 2000);
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        showCreditsCaution: false
                    }
                })
            }).catch(error => {
                dispatch(handleErrors(error))
            }))
        }
    }
}
function resetPassword(history) {
    return (dispatch, getState) => {
        var loggedInUser = getState() && getState().global.loggedInUser ? getState().global.loggedInUser : {}
        let currentUser = getState() && getState().users && getState().users.currentUser ? getState().users.currentUser : {}
        if (currentUser.password && currentUser.confirmPassword) {
            if (currentUser.password != currentUser.confirmPassword) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        isPasswordMatched: false
                    }
                })
            } else {
                currentUser = Object.assign({}, getState().users.uneditUser, {
                    password: currentUser.password,
                    confirmPassword: currentUser.confirmPassword
                })
                dispatch(toggleLoading(true))
                promisesList.push(axios.post(API_URL.UPDATE_PASSWORD, { ...currentUser }).then(response => {
                    dispatch(handleLoading())
                    if (loggedInUser.role == constants.logged_roles.CG) {
                        history.push('/patients/list')
                    } else {
                        history.push('/users/list')
                    }
                    dispatch(toggleNotification(true, constants.notification_type.success, '',
                        [messages.user_update_success]
                        , false))
                }).catch(error => {
                    dispatch(handleErrors(error))
                }))
            }
        } else {
            let errormsg = []
            if (!currentUser.password) { errormsg.push(`${messages.password_req}`) }
            if (!currentUser.confirmPassword) { errormsg.push(`${messages.confirm_pwd_req}`) }
            window.scrollTo(0, 0)
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                errormsg
                , false))
        }
    }
}

function picUploaded(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        if (uploadedFile) {
            let imagetype = uploadedFile.type//.split('/')[uploadedFile.type.split('/').length - 1]
            if (constants.valid_image_types.indexOf(imagetype) != -1) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    let avatarContent = e.target.result
                    let currentUser = getState() && getState().users && getState().users.currentUser ? getState().users.currentUser : {}
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            currentUser: Object.assign({}, currentUser,
                                {
                                    avatarContent: avatarContent.split(',')[1],
                                    avatarContentType: imagetype,
                                    avatarFileSize: uploadedFile.size,
                                    avatarFileName: uploadedFile.name
                                })
                        }
                    })
                }
                reader.readAsDataURL(eve.target.files[0]);
            } else {
                window.scrollTo(0, 0)
                dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_validation_message], false))
            }
        }
    }
}

function removePic(eve) {
    return (dispatch, getState) => {
        eve.preventDefault()
        let currentUser = getState() && getState().users && getState().users.currentUser ? getState().users.currentUser : {}
        document.getElementById("fileInput").value = '';
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: Object.assign({}, currentUser, {
                    avatarContent: null,
                    avatarContentType: null,
                    avatarFileSize: null,
                    avatarFileName: null
                })
            }
        })
    }
}


export function setUsersStateToDefault() {
    return (dispatch, getState) => {
        dispatch({
            type: CLEAR_USERS
        })
    }
}

export function addRoleToCurrentUser() {
    return (dispatch, getState) => {
        let currentUser = getState() && getState().users && getState().users.currentUser ? getState().users.currentUser : {}
        let currentRole = getState().global && getState().global.loggedInUser ? getState().global.loggedInUser.role : {};
        if (currentRole == constants.logged_roles.CG) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentUser: { ...currentUser, role: currentRole }
                }
            })
        }
    }
}

export function setPrimaryMFA(mfaMode){
    return (dispatch, getState) => {
      let currentUser = getState().users && getState().users.currentUser ? getState().users.currentUser : {}  
      dispatch(toggleLoading(true))
      promisesList.push(axios.put(`${API_URL.SET_PRIMARY_MFA}?userId=${currentUser.id}&MFAMode=${mfaMode}`).then((response) => {
        if (response.data && response.data) {
            currentUser = getState().users && getState().users.currentUser ? getState().users.currentUser : {}
            currentUser.userMFA.map(mfa => {
                if (mfa.mfaMode == mfaMode){ mfa.isPrimary = 1 }
                else { mfa.isPrimary = 0 }
            })
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentUser: currentUser
                }
            })
            dispatch(toggleLoading(false))  
        } else {
          dispatch(handleLoading())
          dispatch(toggleLoading(false))
        }
      }).catch((error) => {
        dispatch(handleErrors(error))
      }))
    }
}    

export function exportUser(history){
    return(dispatch, getState) => {
        dispatch(toggleLoading(true))
        let users = getState().users.filteredUsers ? getState().users.filteredUsers : []
        let usersList = users.filter(user => user.active == 1 && (user.isPrimarySiteActive == 1 || user.primarySiteId == 0))
        const headers = Object.keys(userDetails).map(r => userDetails[r])
        let csv = [headers]
        if (usersList && usersList.length) {
            usersList.map(user => {
                csv.push(
                    Object.keys(userDetails).map(a => a == 'originalRole' ? (user[a] ? capitalizeFirstLetter(user[a]) : null) : user[a])
                )
            })
        }
        downloadExcelImproved(csv, en_labels.header_labels.users, constants.users_filename_xlsx)
        dispatch(toggleLoading(false))
    }
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


const initialState = {
    currentUser: { active: 1, isProvider: 0, isPrimaryContact: 0 },
    uneditUser: {},
    isPasswordMatched: true,
    no_grid_data: false
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    },
    [CLEAR_USERS]: (state, action) => {
        return Object.assign({}, initialState)
    }
}
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}
export const actionCreators = {
    getUsers,
    setCurrentUser,
    setSearchParam,
    clearSearch,
    saveUser,
    resetPassword,
    getSites,
    editUser,
    addUser,
    editPassword,
    naviagateEditUser,
    filterUsers,
    picUploaded,
    removePic,
    setUsersStateToDefault,
    addRoleToCurrentUser,
    setPrimaryMFA,
    exportUser
    
}