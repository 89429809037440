import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Type } from 'react-bootstrap-table2-editor';
import labels from '../../../config/localization';
import cellEditFactory from 'react-bootstrap-table2-editor';
import './users-styles.scss';
import FormControl from '../../../components/FormControls/FormControl.js';
import LoadingTemplate from '../../../components/LoadingTemplate';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/scss/font-awesome.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import PageTitle from '../../../components/PageTitle';
import constants from '../../../config/constants';
import { parse } from 'qs';

const breadCrumbs = [
    { text: labels.physioage_label, path: '' },
    { text: labels.user_labels.title, path: '' }
]
class UsersList extends Component {
    componentDidMount() {
        const queryString = this.props.location && this.props.location.search && this.props.location.search.substr(1)
        this.urlParams = parse(queryString, true);
        var role =  this.props.loggedInUser && this.props.loggedInUser.role

        if (!queryString) {
            this.urlParams['status'] = 'active'
            this.urlParams['role'] = (role == constants.logged_roles.AN ? 'all' : 'all')
        }
        else {
            if (!this.urlParams['status']) {
                this.urlParams['status'] = 'active'
            }
            if (!this.urlParams['role']) {
                this.urlParams['role'] = (role == constants.logged_roles.AN ? 'all' : 'all')
            }
        }
        if (role == constants.logged_roles.AN && constants.roles.all_roles.map(u => u.value)
            .indexOf(this.urlParams['role']) == -1) {
            this.props.history.push('/statuscode/404')
        }
        if (role == constants.logged_roles.CG && constants.roles.caregiver.map(u => u.value)
            .indexOf(this.urlParams['role']) == -1) {
            this.props.history.push('/statuscode/404')
        }
        if (constants.active_status.map(u => u.value)
            .indexOf(this.urlParams['status']) == -1) {
            this.props.history.push('/statuscode/404')
        }
        if (Object.keys(this.urlParams).length > (role == constants.logged_roles.AN ? 3 : 2)) {
            this.props.history.push('/users/list')
        }
        this.props.clearSearch(this.urlParams, this.props.history)
        document.title = `${labels.physioage_label}: ${labels.user_labels.title}`
        this.props.setBreadCrumbs(breadCrumbs)
        this.props.setPageTitle(labels.user_labels.title)

        // if (role == constants.logged_roles.AN)
        this.props.getSites()
    }
    componentWillReceiveProps(newProps) {
        if (this.props.location && this.props.location.search
            && newProps.location && !newProps.location.search) {
            var role =  this.props.loggedInUser && this.props.loggedInUser.role

            this.props.clearSearch({
                'status': 'active',
                'role': (role == constants.logged_roles.AN ? 'all' : 'all')
            })
        }
    }
    handleDateSort(a, b, order, dataField, rowA, rowB) {
        // default date 0 is "1970-01-01T00:00:00Z" for "Never"
        let dateA = new Date(rowA.lastRequestAt ? rowA.lastRequestAt : 0)
        let dateB = new Date(rowB.lastRequestAt ? rowB.lastRequestAt : 0)

        return order === 'desc' ? dateA - dateB : dateB - dateA
    }
    render() {
        const columns = [
            {
                formatter: (cellContent, row) => (<FormControl className='user-edit-btn' type='button' btnType='default btn-small'
                    onClick={() => this.props.naviagateEditUser(this.props.history, `/users/edit/${row.id}`)} text={labels.user_labels.edit}></FormControl>)
            },
            {
                dataField: 'name', text: labels.patient_list_labels.name_label,
                sort: true, formatter: (cellContent, row) => (<span>{row.name}</span>)
            },
            {
                dataField: 'site', text: labels.patient_list_labels.site_label, sort: true,
                hidden: this.props.loggedInUser && this.props.loggedInUser.role != constants.logged_roles.AN
            },
            { dataField: 'originalRole', text: labels.user_labels.role, sort: true },
            {
                dataField: 'lastSeen', text: labels.sites_labels.lastseen, sort: true, dataSort: true,
                sortFunc: this.handleDateSort
            },
            {
                dataField: 'active', text: labels.user_labels.activeWithQ, sort: true,
                formatter: (cellContent, row) => (<span>{row.active == "1" ? "Active" : "Inactive"}</span>)
            }

        ]
        // const paginationOptions = paginationFactory({
        //     alwaysShowAllBtns: true,
        //     prePageText: 'Prev',
        //     nextPageText: 'Next',
        //     withFirstAndLast: false,
        //     hideSizePerPage: true
        // });
        //removed pagination
        //pagination={this.props.filteredUsers.length > 10 ? paginationOptions : false}
        return (
            this.props.isLoading ? <>
                <LoadingTemplate haveSidebar={true} isGrid={true} />
            </> : <>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                        <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
                        <div className={`float-right page-title-wrap col-lg-9 col-md-12 col-sm-12 col-xs-12 ${this.props.breadCrumbs && this.props.breadCrumbs.length ? '' : 'no-breadcrumb-header'}`}>
                            <PageTitle breadCrumbs={this.props.breadCrumbs}
                                pageTitle={this.props.pageTitle} subTitle={this.props.subTitle}
                                navigatePatients={this.props.navigatePatients}
                                history={this.props.history}
                                {...this.props} />
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 users-children'>

                        <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2 users-list-sidepanel'>
                            <div className='users-filter'>
                                { this.props.loggedInUser.role == constants.logged_roles.AN ?
                                    <div className='new-user-btn-sec'>
                                        <FormControl type='button' btnType='info' text={labels.user_labels.export_user} onClick={() => this.props.exportUser(this.props.history)} />
                                    </div>
                                : null }
                                <div className='new-user-btn-sec'>
                                    <FormControl type='button' btnType='default' text={labels.user_labels.new_user} onClick={() => this.props.addUser(this.props.history)} />
                                </div>
                                <div>
                                    <div className='card patients-filter mb-4'>
                                        <div className='card-header'>
                                            {labels.user_labels.filter_users}
                                        </div>
                                        <form className='card-body'
                                            onSubmit={(e) => { e.preventDefault(); this.props.getUsers(this.props.history); }}>
                                            {/* {this.props.loggedInUser.role == constants.logged_roles.AN ? */}
                                            <FormControl
                                                id='siteId' label={labels.patient_list_labels.site_label}
                                                type='select' onChange={(e) => this.props.setSearchParam(e.target.value, e.target.id, true, this.props.history)}
                                                defaultOption={labels.patient_list_labels.all_sites}
                                                value={this.props.currentSearchFilters && this.props.currentSearchFilters.siteId ? parseInt(this.props.currentSearchFilters.siteId) : 0}
                                                disabled={!this.props.sites || !this.props.sites.length}
                                                options={this.props.sites && this.props.loggedInUser && this.props.loggedInUser.siteIds ? this.props.loggedInUser.role == 'admin' ? this.props.sites : this.props.sites.filter(site => this.props.loggedInUser.siteIds.includes(site.id)) : []} 
                                                textKey={'name'} valueKey={'id'} />
                                            {/* : null} */}
                                            <FormControl
                                                id='roleID' label={'Role'} name='role' nodefaultOption={true}
                                                value={this.props.currentSearchFilters && this.props.currentSearchFilters.role ? this.props.currentSearchFilters.role : '0'}
                                                onChange={(e) => this.props.setSearchParam(e.target.value, e.target.name, true, this.props.history)}
                                                type='select' options={this.props.loggedInUser.role == constants.logged_roles.AN
                                                    ? constants.roles.all_roles : constants.roles.caregiver}
                                                textKey={'text'} valueKey={'value'}
                                            />
                                            <FormControl
                                                id='statusID' label={'Status'} name='status' nodefaultOption={true}
                                                value={this.props.currentSearchFilters && this.props.currentSearchFilters.status ? this.props.currentSearchFilters.status : '0'}
                                                onChange={(e) => this.props.setSearchParam(e.target.value, e.target.name, true, this.props.history)}
                                                type='select' options={constants.active_status}
                                                textKey={'text'} valueKey={'value'}
                                            />
                                            <FormControl
                                                id='name' label={labels.patient_list_labels.name_label}
                                                type='text' placeholder={"Enter name or email"} value={this.props.searchFieldName || ''}
                                                onChange={(e) => this.props.filterUsers(e.target.value)} />

                                            <FormControl
                                                type='button' btnType='default'
                                                className='search-btn' text={labels.patient_list_labels.search} />
                                        </form>
                                    </div>
                                    {
                                        this.props.loggedInUser.role == constants.logged_roles.AN && this.props.allMails && this.props.allMails.length > 0 &&
                                        <FormControl label={labels.user_labels.email_addresses} type='textarea' value={this.props.allMails} />
                                    }
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0'>
                            <div className='users-table w-auto'>
                                {this.props.filteredUsers && this.props.filteredUsers.length ?
                                    <div className='bootstrap-table-container'>
                                        <BootstrapTable striped={false} bordered={false} hover={true} bootstrap4 keyField='id'
                                            data={this.props.filteredUsers ? this.props.filteredUsers : []} columns={columns}
                                        />
                                    </div>
                                    : <div className='jumbotron'>
                                        <h3>{labels.no_grid_data}</h3>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </>
        )
    }
}
export default UsersList;