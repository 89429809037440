import axiosInstance from 'axios';
import axios from '../lib/axios'
import API_URL from '../config/api';
import { clearSearch, clearFilters } from '../routes/Patients/modules/patients';
import constants from '../config/constants';
import messages from '../config/messages';
import labels from '../config/localization';
import { clearPromises, promisesList } from '../utils/resolve-promises';
import { idleTimer, clearTimer, refreshTokenTimer, tokenTimerReset } from '../utils/resolve-timer';
import resolveRedirect from '../utils/resolve-redirection';
import { isFirefox, b64toBlob } from '../utils/download-utils';
import { constant } from 'lodash';
import documentsList from '../routes/Analytics/demoAnalyticsApi/documentsList';
import { toggleTheme } from '../routes/AnalyticsBeta/modules/analyticsBeta';
import moment from 'moment';

// import {setUsersStateToDefault} from '../routes/Users/modules/user';
const openbleFiles = ['pdf', 'jpg', 'png', 'jpeg']
let apiConfig = window._env_ || {};

//actions
const CLEAR_GLOBAL = 'CLEAR_GLOBAL';
const TOGGLE_LOADING = 'TOGGLE_LOADING';
const TOGGLE_REPORT_LOADING = 'TOGGLE_REPORT_LOADING';
const SET_PROPS = 'SET_PROPS';
const TOGGLE_HEADER_COLLAPSE = 'TOGGLE_HEADER_COLLAPSE';
const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';

const reqFields = {
  caregiver: ['firstName', 'lastName', 'email'],
  site: ['languagePreference', 'name', 'planType', 'unitPreference', 'category', 'billingStrategy', 'code']
}

const reqFieldText = {
  firstName: labels.user_labels.firstname,
  lastName: labels.user_labels.lastname,
  // role: labels.user_labels.role,
  email: labels.user_labels.email,
  // siteId: labels.user_labels.site,
  // password: labels.user_labels.password,
  // confirmPassword: labels.user_labels.confirm_password,
  planType: labels.user_labels.plan,
  languagePreference: labels.sites_labels.language_preference,
  name: labels.sites_labels.name_label,
  unitPreference: labels.sites_labels.unit_preference,
  category: labels.sites_labels.category_label,
  address: labels.sites_labels.address_label,
  city: labels.sites_labels.city_label,
  state: labels.sites_labels.state_label,
  postalCode: labels.sites_labels.postal_code,
  billingStrategy: labels.sites_labels.billing_strategy,
  code: 'GPA Noumenon'
}

const referalReqFields = [
  'address',
  'city',
  'state',
  'postalCode']

function removeTokensInBrowser() {
  localStorage.clear()
  // localStorage.removeItem(constants.accessToken)
  // localStorage.removeItem(constants.refreshToken)
  // localStorage.removeItem('previousurl')
  // localStorage.removeItem('currenturl')
}
export function handleLoading() {
  return (dispatch) => {
    let allPromises = promisesList.filter(p => p)
    Promise.all(allPromises).then(function (values) {
      if (allPromises.length && allPromises.length == values.length) {
        allPromises = []
        clearPromises()
        dispatch(toggleLoading(false))
      }
    });
  }
}

//action creators

export function loginHandler(eve, history) {
  return (dispatch, getState) => {
    eve.preventDefault();
    dispatch(toggleLoading(true))
    let Email = getState().global.email ? getState().global.email : document.querySelector('#email').value
    let Password = getState().global.password ? getState().global.password : document.querySelector('#password').value
    let isWeb = process.env.REACT_APP_MFA_ENABLED
    if (Email && Password) {
      promisesList.push(axios.post(API_URL.LOGIN, { Email, Password, isWeb }).then((response) => {
        console.log(response)
        if (response.data){
          console.log(response.data)
          if(response.data.jwtSecurityToken) {
            dispatch(setAccessToken(response, history))
          }
          else{
            let userData = response.data
            localStorage.setItem('userData', JSON.stringify(userData));
            // dispatch(handleLoading())
              dispatch({
                type: SET_PROPS,
                payload: {
                  userData
                }
              })
            if (userData){
              let isMFAVerified = userData.isMFAVerified
              if (isMFAVerified){
                localStorage.setItem('mfa', false);
                console.log(userData)
                getRememberDevice(userData.userID).then(function(isValid) {
                  if (isValid) {
                    console.log("Cached data is valid.");
                    dispatch(handleSuceessDone())
                  } else {
                    dispatch(handleLoading())
                    history.push('/otp')
                    console.log("Cached data is either expired or not found.");
                  }
                }).catch(function(error) {
                  console.error("Error:", error);
                }); 
              }
              else {
                dispatch(handleLoading())
                history.push('/verify')
              }
            } 
            else {
              dispatch(handleLoading())
              history.push('/login')
            } 
          }
        } else {
          dispatch(handleLoading())
          history.push('/login')
        }
      }).catch((error) => {
        dispatch(handleLoading())
        dispatch(handleErrors(error))
      }))
    } else {
      let errorMsg = []
      if (!Email)
        errorMsg.push(`${labels.user_labels.email} ${labels.required_label}`)
      if (!Password)
        errorMsg.push(`${labels.user_labels.password} ${labels.required_label}`)

      window.scrollTo(0, 0)
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        errorMsg
        , false))
      dispatch(toggleLoading(false))
    }
  };
}


export function sendOTP(MFAMode, MFAValue, IsVerification){
  return (dispatch, getState) => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    let userID = storedUserData && storedUserData.userID ? storedUserData.userID : 0
    dispatch(toggleLoading(true))
    let params =  {
      "UserId": userID,
      "MFAMode": MFAMode,
      "MFAValue": MFAValue,
      "IsVerification": IsVerification
    }
    if (MFAValue) { 
      promisesList.push(axios.post(API_URL.SEND_OTP, {...params}).then((response) => {
        if (response && response.status == 200) {
          dispatch(toggleLoading(false))
        } else {
          dispatch(handleLoading())
          history.push('/login')
        }
      }).catch((error) => {
        dispatch(handleErrors(error))
      }))
    }
    else {
      let errorMsg = []
      errorMsg.push(`${labels.user_labels.email} ${labels.required_label}`)
      window.scrollTo(0, 0)
      dispatch(toggleNotification(true, constants.notification_type.error, 'VerifyEmail',
          errorMsg
          , false))
      dispatch(toggleLoading(false))
    }
  }
}

export function getQRCode(){
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    let userID;
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (getState().global.isAuthenticated){
      userID = getState().global.loggedInUser && getState().global.loggedInUser.id ? getState().global.loggedInUser.id : 0
    }
    else {  
      userID = storedUserData && storedUserData.userID ? storedUserData.userID : 0
    }
    promisesList.push(axios.post(`${API_URL.GET_QRCODE}?userId=${userID}` ).then((response) => {
      if (response && response.status == 200) {
        dispatch(toggleLoading(false))
        dispatch({
          type: SET_PROPS,
          payload: {
            qrcodeDTO: response.data 
          }
        })
      } else {
        dispatch(handleLoading())
        history.push('/login')
      }
    }).catch((error) => {
      dispatch(handleErrors(error))
    }))
  }
}


export function validateOTP(MFAMode, otp, verify, remember){
  return (dispatch, getState) => {
    let userID;
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (getState().global.isAuthenticated){
      userID = getState().global.loggedInUser && getState().global.loggedInUser.id ? getState().global.loggedInUser.id : 0
    }
    else {  
      userID = storedUserData && storedUserData.userID ? storedUserData.userID : 0
    } 
    dispatch(toggleLoading(true))
    let params =  {
      "UserId": userID,
      "MFAMode": MFAMode,
      "OTP": otp,
    }
  if (otp){  
    promisesList.push(axios.post(API_URL.VALIDATE_OTP, {...params}).then((response) => {
      if (response && response.data) {
        if (verify){
          dispatch(toggleLoading(false))
          dispatch(setVerifyDone(userID))
        } else {
          dispatch(handleSuceessDone())
        }
        if (remember){
          setRememberDevice(userID)
        }    
      }
      else {
        let errorMsg = []
        errorMsg.push(`${labels.user_labels.invalid_code}`)
        window.scrollTo(0, 0)
        dispatch(toggleNotification(true, constants.notification_type.error, 'VerifyOTP',
          errorMsg
          , false))
        dispatch(toggleLoading(false))
      }    
    }).catch((error) => {
      dispatch(handleErrors(error))
    }))
  }  
  else {
    let errorMsg = []
      if (!otp)
        errorMsg.push(`${labels.user_labels.code} ${labels.required_label}`)
      window.scrollTo(0, 0)
      dispatch(toggleNotification(true, constants.notification_type.error, 'VerifyOTP',
        errorMsg
        , false))
      dispatch(toggleLoading(false))
  }
}
}

function setRememberDevice(userID){
  console.log("setting Cache")
  caches.open("MFA").then(function(cache) {
    let today = new Date();
    let expirationTimestamp = today.setDate(today.getDate() + 30);
    let data = {
        expiration: expirationTimestamp
    };
    let response = new Response(JSON.stringify(data));
    cache.put(userID, response);
  });
 
}

function getRememberDevice(userID) {
  console.log("getting Cache");
  return caches.open("MFA").then(function(cache) {
    return cache.match(userID);
  }).then(function(response) {
    if (response) {
      return response.text().then(function(data) {
        try {
          const result = JSON.parse(data);
          if (result && result.expiration) {
            return new Date(result.expiration) > new Date();
          } else {
            return false;
          }
        } catch (error) {
          console.error("Failed to parse JSON:", error);
          return false;
        }
      });
    } else {
      console.warn("No match found in the cache.");
      return false;
    }
  }).catch(function(error) {
    console.error("Error accessing cache:", error);
    return false;
  });
}

export function setVerifyDone(){
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        verification: true 
      }
    })
  }
}

export function handleSuceessDone(){
  return (dispatch, getState) => {
    let userID;
    localStorage.setItem('mfa', true);
    dispatch({
      type: SET_PROPS,
      payload: {
        verification: false,
        mfa: true
      }
    })
    if (!getState().global.isAuthenticated){
      const storedUserData = JSON.parse(localStorage.getItem('userData'));
      userID = storedUserData && storedUserData.userID ? storedUserData.userID : 0
      dispatch(getAuth(userID))
    }
    else {
      dispatch(setLoggedInUser())
    }
  }
}

export function getAuth(userID){
  return (dispatch, getState) => {
    // dispatch(toggleLoading(true))
    promisesList.push(axios.post(`${API_URL.GET_AUTH}?userId=${userID}`).then((response) => {
      if (response.data && response.data.jwtSecurityToken) {
        dispatch(setAccessToken(response, history))
      } else {
        dispatch(handleLoading())
        history.push('/login')
      }
    }).catch((error) => {
      dispatch(handleErrors(error))
    }))
  }
}

function setAccessToken(response, history, isMobile) {
  return (dispatch, getState) => {
    localStorage.setItem(constants.accessToken, response.data.jwtSecurityToken)
    let loggedInUser = { ...parseJwtUserAssignment(response.data.jwtSecurityToken) }
    localStorage.setItem(constants.role, constants.roles_enc[loggedInUser.role])
    localStorage.setItem(constants.refreshToken, response.data.refreshToken)
    if (axios.defaults && axios.defaults.headers) {
      axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
    }
    dispatch(toggleNotification(false))
    dispatch({
      type: SET_PROPS,
      payload: {
        loggedInUser

      }
    })
    dispatch({
      type: SET_PROPS,
      payload: {
        isAuthenticated: true,
      },
    });
    let redirectTo = getState().global.redirectTo
      ? getState().global.redirectTo
      : localStorage.getItem('redirectTo')

    if (redirectTo && redirectTo != '/' && redirectTo.indexOf('signup') == -1 && redirectTo.indexOf('termsandconditions') == -1
      && redirectTo.indexOf('resetpassword') == -1 && redirectTo.indexOf('setpassword') == -1
      && redirectTo.indexOf('forgotpassword') == -1 && !isMobile) {
      localStorage.removeItem('redirectTo')
      // history.push(redirectTo)
      window.location.replace(redirectTo)
    }
    else {
      pendo.initialize({
        visitor: {
          id: loggedInUser.id  // Required if user is logged in
          // email:        // Recommended if using Pendo Feedback, or NPS Email
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: loggedInUser.id // Required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      });
      if (!isMobile) {
        if ((loggedInUser.role == constants.logged_roles.AN || loggedInUser.role == constants.logged_roles.CG)
          && window.location.href.indexOf('patients/list') == -1) {
          window.location.replace('/patients/list')
        }
        else if (loggedInUser.role == constants.logged_roles.PT)
          window.location.replace('/analytics')
        else
          window.location.replace('/')


      }
    }
  }
}
function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
  return re.test(email);
}
export function clearUsermail(link) {
  return (dispatch, getState) => {
    if (link) {
      dispatch({
        type: SET_PROPS,
        payload: {
          linkexpired: true
        }

      })
    }
    dispatch({
      type: SET_PROPS,
      payload: {
        forgotUsermail: ''
      }
    })
  }
}

export function resetPassword() {
  return (dispatch, getState) => {
    let forgotUsermail = getState().global && getState().global.forgotUsermail
    let validate = validateEmail(forgotUsermail)
    if (forgotUsermail) {
      if (validate) {
        dispatch(toggleNotification(false))
        axiosInstance({
          method: 'post',
          url: `${apiConfig.API_BASE_URL}${API_URL.FORGOT_PASSWORD}`,
          headers: { 'Content-Type': 'application/json-patch+json' },
          data: `"${forgotUsermail}"`
        }).then(function (response) {
          dispatch({
            type: SET_PROPS,
            payload: {
              sentResetEmail: true,
              linkexpired: false
            }
          })
          dispatch(toggleNotification(true, constants.notification_type.success, '',
            [messages.reset_password_mail_success]
            , false))
        }).catch(error => {
          dispatch(handleErrors(error))
        })

      }
      else {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [labels.invalid_email]
          , false))
      }
    } else {
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        [labels.email_help]
        , false))
    }
  }
}
export function updatePassword(history) {
  return (dispatch, getState) => {
    let Password = getState().global.resetPasswordTxt
    let confirmpassword = getState().global.resetConfirmPasswordTxt
    if (Password && confirmpassword) {
      if (Password == confirmpassword) {
        let PerishableToken = window.location.pathname.split("/").pop()

        axiosInstance({
          method: 'post',
          url: `${apiConfig.API_BASE_URL}${API_URL.RESET_PASSWORD}`,
          headers: { 'Content-Type': 'application/json-patch+json' },
          params: { PerishableToken },
          data: `"${Password}"`
        }).then(function (response) {
          dispatch(toggleNotification(true, constants.notification_type.success, '',
            [messages.password_updated]
            , false))
          // history.location = { ...window.location, href: '/login' }
          // history.replace(history)
          window.location.replace('/login')
          // history.push('/login')
        }).catch(errors => dispatch(handleErrors(errors)))
      } else {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [labels.user_labels.password_not_matched]
          , false))
      }
    } else {
      let errorMsg = []
      if (!Password) {
        errorMsg.push(messages.password_req)
      }
      if (!confirmpassword) {
        errorMsg.push(messages.confirm_pwd_req)
      }
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        errorMsg
        , false))
    }
  }
}
export function setPassword(history) {
  return (dispatch, getState) => {
    let Password = getState().global.resetPasswordTxt
    let confirmpassword = getState().global.resetConfirmPasswordTxt
    if (Password && confirmpassword) {
      if (Password == confirmpassword) {
        let PerishableToken = window.location.pathname.split("/").pop()
        axiosInstance({
          method: 'post',
          url: `${apiConfig.API_BASE_URL}${API_URL.SET_PASSWORD}`,
          headers: { 'Content-Type': 'application/json-patch+json' },
          params: { PerishableToken },
          data: `"${Password}"`
        }).then(function (response) {
          // if (response.data && response.data.jwtSecurityToken) {
            history.push('/login')
            // dispatch(setAccessToken(response, history))
          // }
        }).catch(errors => dispatch(handleErrors(errors)))
      } else {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [labels.user_labels.password_not_matched]
          , false))
      }
    } else {
      let errorMsg = []
      if (!Password) {
        errorMsg.push(messages.password_req)
      }
      if (!confirmpassword) {
        errorMsg.push(messages.confirm_pwd_req)
      }
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        errorMsg
        , false))
    }
  }
}
export function headerNavigation(path, history) {
  return (dispatch, getState) => {
    if (path) {
      history.push(path)
    }
    dispatch({
      type: SET_PROPS,
      payload: {
        dropdownOpen: ''
      }
    })
    if (window.innerWidth <= constants.small_screen_resolution && path) {
      dispatch(headerCollapseToggle())
    }
    if (getState() && getState().global && getState().global.notifications && getState().global.notifications.notificationType == constants.notification_type.error)
      dispatch(toggleNotification(false))
  }
}
export function toggleHeaderNavigation(display) {
  return (dispatch, getState) => {
    let existingDisplay = getState().global.dropdownOpen
    dispatch({
      type: SET_PROPS,
      payload: {
        dropdownOpen: existingDisplay == display ? '' : display
      }
    })
  }
}
export function clearSentMailStatus() {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        sentResetEmail: false
      }
    })
  }
}
//to parse jwtoken
function parseJwtUserAssignment(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let resultant = JSON.parse(window.atob(base64))
  let role = Object.keys(resultant).filter(res => res.indexOf('/role') != -1)
  let loggedInUser = Object.keys(resultant).filter(res => res.indexOf('/name') != -1)
  return { ...resultant, role: resultant[_.head(role)], id: resultant[_.head(loggedInUser)] };
};
function parseJWT() {
  let token = localStorage.getItem(constants.accessToken)
  if (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let resultant = JSON.parse(window.atob(base64))
    return resultant
  }
  return {}
}
export function logOut(history, isSessionExpired) {
  return dispatch => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isAuthenticated: false
      }
    })
    removeTokensInBrowser()
    // setUsersStateToDefault();
    clearInterval(refreshTokenTimerHandler)
    // tokenTimerReset()
    //set redirect url-when session expired
    if (isSessionExpired) {
      let changed = resolveRedirect()
      if (changed) {
        dispatch({
          type: SET_PROPS,
          payload: {
            redirectTo: localStorage.getItem('redirectTo')
          }
        })
      }
    } else {
      localStorage.removeItem('redirectTo');

    }

    if (axios.defaults && axios.defaults.headers) {
      delete axios.defaults.headers.Authorization
    }
    // dispatch({
    //   type: SET_PROPS,
    //   payload: {
    //     isAuthenticated: false,
    //   },
    // });
    dispatch({
      type: CLEAR_GLOBAL
    })
    // clearTimer()
    // clearInterval(refreshTokenTimerHandler)
    // clearInterval(idleTimerHandler)
    if (history) {
      // history.location = { ...window.location, href: '/login' }
      history.push('/login')
    }
    else
      window.location.href = '/login';
  };
}
export function getAuthStatus() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isAuthenticated: localStorage.getItem(constants.accessToken) ? true : false,
      }
    })
  }
}
export function setBreadCrumbs(breadCrumbs) {
  return dispatch => {
    let resbreadCrumbs = [...breadCrumbs]
    dispatch({
      type: SET_PROPS,
      payload: {
        breadCrumbs: resbreadCrumbs
      }
    })
    // dispatch(toggleLoading(false))
  }
}
export function setPageTitle(pageTitle, subTitle) {
  return dispatch => {
    dispatch({
      type: SET_PROPS,
      payload: {
        pageTitle,
        subTitle
      }
    })
  }
}
export function headerCollapseToggle(status) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_HEADER_COLLAPSE,
      payload: (status != null && status != undefined && typeof (status) == 'boolean') ? status : null
    })
  }
}
export function toggleLoading(loadingStatus) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LOADING,
      payload: loadingStatus
    })
  }
}

export function toggleReportLoading(loadingReportStatus) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_REPORT_LOADING,
      payload: loadingReportStatus
    })
  }
}
export function setHistory(history) {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        history
      }
    })
  }
}
//ToDo: Auto refresh token
export function autoRefreshToken(onload, history) {
  return (dispatch) => {
    let accessToken = localStorage.getItem(constants.accessToken)
    let refreshToken = localStorage.getItem(constants.refreshToken)
    // if ((refreshTokenTimer && !isNaN(parseInt(refreshTokenTimer))) && refreshTokenTimer != null && refreshTokenTimer != undefined)
    //   refreshTokenTimer = 0
    // if ((idleTimer || onload) && refreshToken && accessToken) {
    axios.post(API_URL.REFRESH_TOKEN, { accessToken, refreshToken }).then(response => {
      localStorage.setItem(constants.accessToken, response.data.jwtSecurityToken)
      localStorage.setItem(constants.refreshToken, response.data.refreshToken)
      if (axios.defaults && axios.defaults.headers) {
        axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
      }
      localStorage.setItem('isTokenRefreshed', 0)
    }).catch(errors => {
      dispatch(logOut(history, true))
    })
    // } else if (idleTimer) {
    //   dispatch({
    //     type: SET_PROPS,
    //     payload: {
    //       redirectTo: localStorage.getItem('redirectTo')
    //     }
    //   })
    // } else {
    //   dispatch(logOut(history, true))
    // }
  }
}
export function checkAccessToken(isMobile, perishableToken) {
  return (dispatch, getState) => {
    if (!isMobile) {
      let isTokenRefreshed = localStorage.getItem('isTokenRefreshed')
      let parsedToken = parseJWT()
      if (Object.keys(parsedToken).length) {
        if (Date.now() > (parsedToken.exp * 1000)) {
          dispatch(logOut(null, true))
        }
        //if expiration of token is in 40min or less
        else if (((parsedToken.exp * 1000) - Date.now() <= constants.timeout_min_for_expire * 60 * 1000) && !isNaN(parseInt(isTokenRefreshed)) && !parseInt(isTokenRefreshed)) {
          localStorage.setItem('isTokenRefreshed', 1)
          dispatch(autoRefreshToken())
        } else {
          localStorage.setItem('isTokenRefreshed', 0)
        }
      } else {
        dispatch(logOut(null, true))
      }
    }
    else {
      promisesList.push(axios.get(`${API_URL.ACCESS_TOKEN}?token=${perishableToken}`).then((response) => {
        if (response.data && response.data.jwtSecurityToken) {
          dispatch(setAccessToken(response, history, isMobile))
        }
      }).catch(error => {
      }))
    }
  }
}
export function handleErrors(error) {
  return (dispatch, getState) => {
    dispatch(handleLoading())
    document.body.style.overflow = 'inherit'

    // dispatch(toggleLoading(false))
    let accessToken = localStorage.getItem(constants.accessToken)
    let refreshToken = localStorage.getItem(constants.refreshToken)
    // let isRefreshed = getState().global.isRefreshed ? getState().global.isRefreshed : false
    if (error && error.response
      && error.response.status == constants.status_codes.unauthorized
      && error.response.headers && error.response.headers['token-expired'] == 'true'
      && refreshToken && accessToken) {
      dispatch(logOut(null, true))
      // dispatch(autoRefreshToken())
      // window.location.reload()

    } else if (error && error.response
      && error.response.status == constants.status_codes.unauthorized) {
      dispatch(toggleLoading(false))
      removeTokensInBrowser()
      window.location.href = '/login'
    } else if (error && error.response && (error.response.data == labels.unauth_login)) {
      getState().global.history.push('/patients/list')
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        [error.response.data]
        , false, 5000))
      // dispatch(toggleLoading(false))
    }
    else if (error && error.response && error.response.data) {
      if (typeof (error.response.data) == constants.string) {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [error.response.data]
          , false))
        dispatch(toggleLoading(false))
      }
      else if (error.response.data.value) {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [error.response.data.value]
          , false))
        dispatch(toggleLoading(false))
      }
      else if (error.response.data.Message) {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [error.response.data.Message]
          , false))
        dispatch(toggleLoading(false))
      } else if (error.response.data.error && error.response.data.error.message) {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          [error.response.data.error.message]
          , false))
        dispatch(toggleLoading(false))
      } else {
        dispatch(toggleNotification(true, constants.notification_type.error, '',
          Object.keys(error.response.data).map(res => {
            return (error.response.data[res][0])
          })
          , false))
        dispatch(toggleLoading(false))
      }
    }
    else if (error && error.message && typeof (error.message) == constants.string) {
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        [error.message]
        , false))
      dispatch(toggleLoading(false))

    }
    else if (error) {
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        [labels.something_went_wrong]
        , false))
      dispatch(toggleLoading(false))

    }
    if (error && error.response && error.response.status) {
      if (error.response.status == constants.status_codes.badgateway
        || error.response.status == constants.status_codes.gatewaytimeout
        || error.response.status == constants.status_codes.forbidden
        || error.response.status == constants.status_codes.internalserver
      )
        dispatch(handleStatusCodes(error))
    }
  }
}

export function handleStatusCodes(error) {
  return (dispatch, getState) => {
    //removing history dependency as we are not using //&& getState().global.history
    //confirmation from lohith
    if (error.response && (error.response.status && (error.response.status) != constants.status_codes.badrequest
      && Object.keys(constants.validaStatusCodes).indexOf(error.response.status.toString()) != -1) && getState().global) {
      window.location.replace(`/statuscode/${error.response.status}`)
    }
    else if (error.response && error.response.status && (error.response.status) == constants.status_codes.badrequest
      && getState().global.loggedInUser && getState().global.loggedInUser.role == 'patient') {
      window.location.replace('/analytics')
    }
    else if (error.response && error.response.status && (error.response.status) == constants.status_codes.badrequest && getState().global && getState().global.history) {
      window.location.replace('/patients/list')
    }
  }
}
export function toggleNotification(isNotify, notificationType, title, texts, closable, timeout) {
  return (dispatch, getState) => {
    if (isNotify) {
      window.scrollTo(0, 0)
    }
    dispatch({
      type: SET_PROPS,
      payload: {
        notifications: {
          notificationType,
          title,
          texts,
          closable
        }
      }
    })
    dispatch({
      type: SET_PROPS,
      payload: {
        isNotify
      }
    })
    if (timeout) {
      setTimeout(() => {
        dispatch({
          type: SET_PROPS,
          payload: {
            isNotify: false
          }
        })
      }, timeout)
    }

    // if (notificationType == constants.notification_type.success && !timeout) {
    //   // setTimeout(() => {
    //     dispatch({
    //       type: SET_PROPS,
    //       payload: {
    //         isNotify: false
    //       }
    //     })
    //   // }, 5000)
    // }
  }
}

export function loginInput(eve) {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        [eve.target.id]: eve.target.value
      }
    })
  }
}
export function navigatePatients(history) {
  return (dispatch) => {
    if (history && history.location && history.location.pathname == '/patients/list' && !history.location.search)
      dispatch(clearFilters(true))
    // dispatch(getPatients())
    history.push('/patients/list')
  }
}

export function setLoggedInUser() {
  return (dispatch, getState) => {
    let userParam = getState().global && getState().global.loggedInUser
      && getState().global.loggedInUser.id ? getState().global.loggedInUser.id : null
    if (axios.defaults && axios.defaults.headers && !axios.defaults.headers.Authorization) {
      axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
    }
    // promisesList.push(axios.get(`${API_URL.FAILURE_MESSAGE_COUNT}?type=Unrouted`).then((failureMessage) => {
    //   dispatch(toggleToaster(true))
    //   dispatch({
    //     type: SET_PROPS,
    //     payload: {
    //       routeFailureCount: failureMessage.data
    //     }
    //   })
    // }).catch(error => {
    //   dispatch(handleErrors(error))
    // }))
    promisesList.push(axios.get(userParam ? `${API_URL.USERS_URL}?id=${userParam}` : API_URL.USERS_URL).then((userresponse) => {
      pendo.initialize({
        visitor: {
          id: userresponse.data.id  // Required if user is logged in
          // email:        // Recommended if using Pendo Feedback, or NPS Email
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: userresponse.data.id // Required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        }
      });
      if (userresponse.data && userresponse.data.id && document.location.pathname.includes('analytics')) {
        let theme = userresponse.data.isDarkMode ? 'light-theme' : 'dark-theme'
        dispatch(toggleTheme(theme))
      }

      if (userresponse.data && userresponse.data.primarySiteId) {
        promisesList.push(axios.get(`${API_URL.SITE_URL}/${userresponse.data.primarySiteId}`).then(response => {
          // dispatch(handleLoading())
          if (response.data && response.data.credits <= constants.minimum_credits && response.data.billingStrategy != constants.billing_strategy_recurring) {
            dispatch(setCreditsCaution(true))
          } else if (response.data && response.data.credits > constants.minimum_credits && response.data.billingStrategy != constants.billing_strategy_recurring) {
            dispatch(setCreditsCaution(false))
          }
          else if (response.data.billingStrategy == constants.billing_strategy_recurring) {
            dispatch(setCreditsCaution(false))
          }
          dispatch(setUserData({ ...userresponse.data, site: response.data }))
        }).catch(error => {
          dispatch(handleErrors(error))
        }))
      }
      dispatch(setUserData({ ...userresponse.data }))
    }).catch(error => {
      dispatch(handleErrors(error))
    }))
  }
}
function setUserData(data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        loggedInUser: data
      }
    })
    localStorage.setItem(constants.role, constants.roles_enc[data.role])

  }
}
export function setCreditsCaution(status) {
  return dispatch => {
    dispatch({
      type: SET_PROPS,
      payload: {
        showCreditsCaution: status
      }
    })
  }
}
export function setPatientReportStatus(status) {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isPatientReport: status
      }
    })
  }
}
export function validateResetPassword(token, perishcode, history) {
  return (dispatch) => {
    promisesList.push(axios.get(`${API_URL.VALIDATERESETPASSWORD}?AccessToken=${token}&PerishableToken=${perishcode}`).then((response) => {
    }).catch(error => {
      history.push('/forgotpassword')
      dispatch({
        type: SET_PROPS,
        payload: {
          sentResetEmail: false,
          linkexpired: true
        }
      })
      dispatch(clearUsermail(true))
    }))
  }
}
export function validateSetPassword(token, history) {
  return (dispatch) => {
    promisesList.push(axios.get(`${API_URL.VALIDATESETPASSWORD}?token=${token}`).then((response) => {
      if (response.data && response.data.jwtSecurityToken) {
        dispatch(setAccessToken(response, history))
      }
    }).catch(error => {
      history.push('/login')
    }))
  }
}
export function hideHeaderSkeleton(status) {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isHeaderSkeletonHidden: status
      }
    })
  }
}
export function noHeaderLoading(status) {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isNoHeaderLoading: status
      }
    })
  }
}
export function makeParentContainer() {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isParentContainer: window.innerWidth < 1610 ? true : false
        // isParentContainer: false
      }
    })
  }
}
export function setModelWidth() {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isSmallScreen: window.innerWidth <= 768 ? true : false,
        isVerySmallScreen: window.innerWidth <= 530 ? true : false
      }
    })
  }
}
export function toggleToaster(status, redirectUri, history) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        isToasterActive: status
      }
    })
    if (redirectUri) {
      history.push(redirectUri)
    }
  }
}
export function ignoreToaster() {
  return (dispatch, getState) => {
    localStorage.setItem('displayToaster', 'no');
    dispatch({
      type: SET_PROPS,
      payload: {
        isToasterActive: false
      }
    })
  }
}
export function setAllPatientDetails() {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    promisesList.push(axios.get(API_URL.PATIENTS_DETAILS_URL).then(response => {
      dispatch({
        type: SET_PROPS,
        payload: {
          allPatientDetails: response.data
        }
      })
      dispatch(handleLoading())
    }).catch(errors => {
      dispatch(handleErrors(errors))
    }))
  }
}
export function getApiCollection() {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    promisesList.push(axios.get(API_URL.API_COLLECTION_URL).then(response => {
      dispatch({
        type: SET_PROPS,
        payload: {
          apiCollection: response.data
        }
      })
      dispatch(handleLoading())
    }).catch(errors => {
      dispatch(handleErrors(errors))
    }))
  }
}
export function getReferralSites(acceptRef) {
  return (dispatch, getState) => {
    dispatch(toggleLoading(true))
    promisesList.push(axios.get(`${API_URL.SITE_REFERRAL}?accepts_referrals=${acceptRef}`).then(response => {
      dispatch({
        type: SET_PROPS,
        payload: {
          allSitesRef: response.data,
        }
      })
      dispatch(handleLoading())
    })
      .catch((error) => {
        dispatch({
          type: SET_PROPS,
          payload: {
            referralError: "You are not allowed to do that.",
          }
        })
        dispatch(handleLoading())
        //dispatch(handleStatusCodes(error))
        // dispatch(handleErrors(error))
      }))
  }
}
export function downloadDocument(doc, history, isDemo, isMobile = false) {
  return (dispatch, getState) => {
    // if (openbleFiles.indexOf(doc.format) != -1) {
    //     history.push(`/documents/${doc.documentId}.${doc.format}`)
    // }
    // else {
    dispatch(toggleLoading(true))
    dispatch(noHeaderLoading(true))
    !isDemo ?
      promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_ID}?documentId=${doc.documentId}&renderBase64=${true}&isMobile=${isMobile}`).then(response => {
        let responseData = response.data ? response.data.value : null

        const downloadLink = document.createElement('a');
        if (!isFirefox()) {
          let pdfblob = b64toBlob(`data:${responseData.fileContentType};base64,` + responseData.documentFileContent);
          downloadLink.href = URL.createObjectURL(pdfblob)
        } else {
          downloadLink.href = `data:${responseData.fileContentType};base64,` + responseData.documentFileContent
        }

        downloadLink.className = 'hidden';
        document.body.appendChild(downloadLink);

        // downloadLink.setAttribute('download', `${responseData.title}.${responseData.fileContentType.split('/').pop()}`);
        downloadLink.setAttribute('download', `${responseData.fileFileName}`);

        downloadLink.click();
        window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
        dispatch(handleLoading())
        dispatch(noHeaderLoading(false))

      }).catch(error => {
        dispatch(handleErrors(error))
      }))
      :
      dispatch(LoadDemoDocuments(doc))
    // }
  }
}
function LoadDemoDocuments(doc) {
  return (dispatch, getState) => {
    var documents = window.location.pathname.indexOf('demo_1') == 1 || window.location.pathname.indexOf('beta_demo_1') == 1 ? documentsList : null
    let responseData = documents ? _.head(documents.filter(s => s.id == doc.documentId)) : {}
    if (responseData) {
      const downloadLink = document.createElement('a');
      if (!isFirefox()) {
        let pdfblob = b64toBlob(`data:${responseData.fileContentType};base64,` + responseData.documentFileContent);
        downloadLink.href = URL.createObjectURL(pdfblob)
      } else {
        downloadLink.href = `data:${responseData.fileContentType};base64,` + responseData.documentFileContent
      }

      downloadLink.className = 'hidden';
      document.body.appendChild(downloadLink);

      // downloadLink.setAttribute('download', `${responseData.title}.${responseData.fileContentType.split('/').pop()}`);
      downloadLink.setAttribute('download', `${responseData.fileFileName}`);

      downloadLink.click();
      window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
    }
    dispatch(toggleLoading(false))
    dispatch(noHeaderLoading(false))
  }
}

export function editSignUpUser(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let key = eve.target.id;
    if (eve.target.name) {
      key = eve.target.name;
    }
    let value = eve.target.value;
    if (eve.target.type == 'checkbox') {
      value = eve.target.checked ? 1 : 0
    }
    let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser ? { ...getState().global.currentSignUpUser, [key]: value } : {}
    if (eve.target.id == 'role') {
      currentSignUpUser.isProvider = eve.target.value != 'caregiver' ? 1 : 0
    }
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpUser
      }
    })

  }
}

export function resetSignUpPassword(history) {
  return (dispatch, getState) => {
    let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser ? getState().global.currentSignUpUser : {}
    if (currentSignUpUser.password && currentSignUpUser.confirmPassword) {
      if (currentSignUpUser.password != currentSignUpUser.confirmPassword) {
        dispatch({
          type: SET_PROPS,
          payload: {
            isPasswordMatched: false
          }
        })
      } else {
        currentSignUpUser = Object.assign({}, getState().global.currentSignUpUser, {
          password: currentSignUpUser.password,
          confirmPassword: currentSignUpUser.confirmPassword
        })
        dispatch(toggleLoading(true))
        promisesList.push(axios.post(API_URL.UPDATE_PASSWORD, { ...currentSignUpUser }).then(response => {
          dispatch(handleLoading())
          history.push('/login')

          dispatch(toggleNotification(true, constants.notification_type.success, '',
            [messages.user_update_success]
            , false))
        }).catch(error => {
          dispatch(handleErrors(error))
        }))
      }
    } else {
      let errormsg = []
      if (!currentSignUpUser.password) { errormsg.push(`${messages.password_req}`) }
      if (!currentSignUpUser.confirmPassword) { errormsg.push(`${messages.confirm_pwd_req}`) }
      window.scrollTo(0, 0)
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        errormsg
        , false))
    }
  }
}


export function editSignUpPassword(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser
      ? { ...getState().global.currentSignUpUser, [eve.target.id]: eve.target.value } : {}
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpUser: currentSignUpUser,
        isPasswordMatched: (currentSignUpUser.password && currentSignUpUser.confirmPassword) ? currentSignUpUser.password == currentSignUpUser.confirmPassword : true
      }
    })
  }
}

export function uploadSignUpUserPic(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let uploadedFile = eve.target.files ? eve.target.files[0] : null
    if (uploadedFile) {
      let imagetype = uploadedFile.type//.split('/')[uploadedFile.type.split('/').length - 1]
      if (constants.valid_image_types.indexOf(imagetype) != -1) {
        var reader = new FileReader();

        reader.onload = function (e) {
          let avatarContent = e.target.result
          let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser ? getState().global.currentSignUpUser : {}
          dispatch({
            type: SET_PROPS,
            payload: {
              currentSignUpUser: Object.assign({}, currentSignUpUser,
                {
                  avatarContent: avatarContent.split(',')[1],
                  avatarContentType: imagetype,
                  avatarFileSize: uploadedFile.size,
                  avatarFileName: uploadedFile.name
                })
            }
          })
        }
        reader.readAsDataURL(eve.target.files[0]);
      } else {
        window.scrollTo(0, 0)
        dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_validation_message], false))
      }
    }
  }
}

export function removeSignUpUserPic(eve) {
  return (dispatch, getState) => {
    eve.preventDefault()
    let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser ? getState().global.currentSignUpUser : {}
    document.getElementById("fileInput").value = '';
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpUser: Object.assign({}, currentSignUpUser, {
          avatarContent: null,
          avatarContentType: null,
          avatarFileSize: null,
          avatarFileName: null
        })
      }
    })
  }
}

export function editSignUpSite(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let currentSignUpSite = getState() && getState().global ? getState().global.currentSignUpSite : {}
    let value = eve.target && eve.target.type != 'checkbox' ? eve.target.value : eve.target.checked ? 1 : 0
    if (eve.target.id == 'category' && value != 'Clinic' && value != '0') {
      currentSignUpSite.acceptsReferrals = 0
    }
    else if (eve.target.id == 'category' && value == 'Clinic') {
      currentSignUpSite.acceptsReferrals = 1
    }
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: Object.assign({}, currentSignUpSite, { [eve.target.id]: value })
      }
    })
  }
}

function setUuid() {
  const min = 1;
  const max = 1000;
  const rand = min + Math.random() * (max - min);
  return rand
}

export function setNoumenonFields() {
  return (dispatch) => {
      dispatch({
          type: SET_PROPS,
          payload: {
              CreateNoumenonList: [
                  {
                      code: '',
                      startDate: '',
                      endDate: '', 
                      uuid: setUuid()
                  }
              ]
          }
      })
  }
}

export function addNoumenonFields() {
  return (dispatch, getState) => {
      dispatch({
          type: SET_PROPS,
          payload: {
              CreateNoumenonList: [
                  ...getState().global.CreateNoumenonList,
                  {
                      code: '',
                      startDate: '',
                      endDate: '', 
                      uuid: setUuid(),
                  }
              ]
          }
      })
  }
}

export function removeNoumenonFields(uuid) {
  return (dispatch, getState) => {
      let CreateNoumenonList = getState().global.CreateNoumenonList
      CreateNoumenonList = CreateNoumenonList.filter((cf) => cf.uuid != uuid)
      CreateNoumenonList = [
          ...CreateNoumenonList
      ]
      let currentSignUpSite = getState().global.currentSignUpSite 
      currentSignUpSite.noumenonCodes = CreateNoumenonList

      dispatch({
          type: SET_PROPS,
          payload: {
              CreateNoumenonList: CreateNoumenonList
          }
      })
  }
}

export function editNoumenon(index, eve, uuid) {
  return (dispatch, getState) => {
      dispatch(toggleNotification(false));
      let CreateNoumenonList = [...getState().global.CreateNoumenonList];
      let nouemonListLength = CreateNoumenonList.length
      let updatedNoumenon = {
          ...CreateNoumenonList[index],
          [eve.target.id]: eve.target.value
      };
      CreateNoumenonList[index] = updatedNoumenon;
      let currentSignUpSite = getState().global.currentSignUpSite 
      if (CreateNoumenonList.length == 1 && ((CreateNoumenonList[0].code == '0' || CreateNoumenonList[0].code == '') &&  (!CreateNoumenonList[0].startDate) && (!CreateNoumenonList[0].endDate))){
          currentSignUpSite.noumenonCodes = []
      } else{
          currentSignUpSite.noumenonCodes = CreateNoumenonList
      }
      dispatch({
          type: SET_PROPS,
          payload: {
              CreateNoumenonList: CreateNoumenonList,
              currentSignUpSite: currentSignUpSite
          }
      });
      if (eve.target.id == 'code' &&  nouemonListLength == index + 1){
        dispatch(addNoumenonFields())
      }
  };
}

export function GetAllNoumenonNames(){
  return (dispatch) => {
    promisesList.push(axios.get(`${API_URL.GET_ALL_NOUMENON_NAMES}`).then(response => {
        dispatch({
            type: SET_PROPS,
            payload: {
              allNoumenonNameList: response.data,
            }
        })
    }).catch(error => {
        dispatch(handleErrors(error))
    }))
  }
}

export function setCurrentSignUpSite() {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: {
          languagePreference: _.head(constants.language_preference).code,
          datePreference: _.head(constants.date_preference).code,
          includePhoneInSummary: 1,
          acceptsMessages: 1,
          acceptsReferrals: 1,
          enableDocuments: 1,
          category: _.head(constants.site_categories).code,
          billingStrategy: _.last(constants.billing_strategy).value,
          mfaRequired: 1
        }
      }
    })
  }
}

export function setCurrentSignUpUser() {
  return (dispatch) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpUser: {
          active: 1,
          role: constants.logged_roles.CG,
          can_remove_patients: 0,
          isProvider: 1
        }
      }
    })
  }
}

export function logoUploadedInSignUp(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let uploadedFile = eve.target.files ? eve.target.files[0] : null
    let fieldType = eve.target.id
    let imagetype = uploadedFile.type//.split('/')[uploadedFile.type.split('/').length - 1]
    if (uploadedFile) {
      if (constants.valid_image_types.indexOf(imagetype) != -1) {
        var reader = new FileReader();
        reader.onload = function (e) {
          let avatarContent = e.target.result

          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            if (this.width < 150 || this.height < 150) {
              window.scrollTo(0, 0)
              dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_size_validation], false))
            }
            else {
              let currentSignUpSite = getState() && getState().global ? getState().global.currentSignUpSite : {}
              dispatch({
                type: SET_PROPS,
                payload: {
                  currentSignUpSite: Object.assign({}, currentSignUpSite,
                    {
                      [`${fieldType}File`]: avatarContent.split(',')[1],
                      [`${fieldType}ContentType`]: imagetype,
                      [`${fieldType}FileSize`]: uploadedFile.size,
                      [`${fieldType}FileName`]: uploadedFile.name,
                      [`bannerLogoFile`]: avatarContent.split(',')[1],
                      [`bannerLogoContentType`]: imagetype,
                      [`bannerLogoFileSize`]: uploadedFile.size,
                      [`bannerLogoFileName`]: uploadedFile.name
                    })
                }
              })
            }
          }
        }
        reader.readAsDataURL(eve.target.files[0]);
      } else {
        window.scrollTo(0, 0)
        dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_validation_message], false))
      }
    }
  }
}

export function removeLogoPicInSignUp(eve, changeInputVal, fieldType) {
  return (dispatch, getState) => {
    eve.preventDefault()
    changeInputVal.inputEntry.value = ""
    let currentSignUpSite = getState().global.currentSignUpSite ? getState().global.currentSignUpSite : {}
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: Object.assign({}, currentSignUpSite, {
          [`${fieldType}File`]: null,
          [`${fieldType}ContentType`]: null,
          [`${fieldType}FileSize`]: null,
          [`${fieldType}FileName`]: null,
          [`bannerLogoFile`]: null,
          [`bannerLogoContentType`]: null,
          [`bannerLogoFileSize`]: null,
          [`bannerLogoFileName`]: null
        })
      }
    })
  }
}


function validateWebsite(website) {
  var s = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  return s.test(website)
}

export function saveSignUpUser(history) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        showMessage: true
      }
    })
    // var loggedInUser = getState() && getState().global.loggedInUser ? getState().global.loggedInUser : {}
    let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser ? getState().global.currentSignUpUser : {}
    let currentSignUpSite = getState().global.currentSignUpSite ? getState().global.currentSignUpSite : {}
    let defaultPanels = "";

    if (!currentSignUpSite.hasOwnProperty('billingStrategy')) {
      let billingStrategy = _.head(constants.billing_strategy).value
      currentSignUpSite = { ...currentSignUpSite, billingStrategy }
    }

    //HONORIC PREFIX HAVE BEEN REMOVED
    // if (currentSignUpUser.honorificPrefix == '0')
    //   delete currentSignUpUser.honorificPrefix

    if (currentSignUpSite.bloodAndUrinePanels || currentSignUpSite.optionalBiomarkerPanels) {
      if (currentSignUpSite.bloodAndUrinePanels && !currentSignUpSite.optionalBiomarkerPanels) {
        defaultPanels = currentSignUpSite.bloodAndUrinePanels;
      }
      else if (!currentSignUpSite.bloodAndUrinePanels && currentSignUpSite.optionalBiomarkerPanels) {
        defaultPanels = currentSignUpSite.optionalBiomarkerPanels;
      }
      else {
        defaultPanels = currentSignUpSite.bloodAndUrinePanels + "," + currentSignUpSite.optionalBiomarkerPanels;
      }
    }
    else {
      delete currentSignUpSite.defaultPanels;
    }
    if (defaultPanels == "") {
      defaultPanels = currentSignUpSite.unitPreference == "Imperial" ? "Vitals" : "Metric Vitals";
    }
    else {
      defaultPanels = currentSignUpSite.unitPreference == "Imperial" ? defaultPanels + "," + "Vitals" : defaultPanels + "," + "Metric Vitals";
    }
    currentSignUpSite = { ...currentSignUpSite, defaultPanels };


    for (let property in currentSignUpSite) {
      if (currentSignUpSite[property] == "")
        delete currentSignUpSite[property];
    }
    for (let property in currentSignUpUser) {
      if (currentSignUpUser[property] == "" && property != "userSurvey" && property != "isProvider")
        delete currentSignUpUser[property];
    }

    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: currentSignUpSite,
        currentSignUpUser: currentSignUpUser
      }
    });

    // if (currentSignUpSite["acceptTermsAndConditions"] != 1) {
    //   let errorMsg = []
    //   errorMsg.push(messages.accept_terms);
    //   dispatch(toggleLoading(false));
    //   dispatch(toggleNotification(true, constants.notification_type.error, '', errorMsg, false));
    // }
    // if(currentSignUpUser.role != constants.logged_roles.CG && currentSignUpUser.avatarFileName) 
    // {
    //     delete currentUser.avatarContent
    //     delete currentUser.avatarContentType
    //     delete currentUser.avatarFileSize
    //     delete currentUser.avatarFileName
    // }
    let reqUserFields = reqFields[constants.logged_roles.CG].filter(rf => (!currentSignUpUser[rf] || parseInt(currentSignUpUser[rf]) == 0))
    let reqSiteFields = reqFields[constants.site].filter(rf => ((!currentSignUpSite[rf] && rf != 'code') || (parseInt(currentSignUpSite[rf]) == 0 && rf != 'name') || (rf == 'code' && currentSignUpSite.noumenonCodes && currentSignUpSite.noumenonCodes.some(noumenon => (noumenon.code == '' || noumenon.code == '0') && (noumenon.startDate || noumenon.endDate)))))
    let req = reqUserFields.concat(reqSiteFields);
    if ((req && req.length)) {
      window.scrollTo(0, 0)
      dispatch(toggleNotification(true, constants.notification_type.error, '',
        req.map(r => `${reqFieldText[r]} ${labels.required_label}`)
        , false))
    } else if (currentSignUpUser.password != currentSignUpUser.confirmPassword) {
      dispatch({
        type: SET_PROPS,
        payload: {
          isPasswordMatched: false
        }
      })
    }
    else if (currentSignUpSite.acceptTermsAndConditions != 1) {
      let err = []
      err.push(messages.accept_terms);
      dispatch(toggleLoading(false));
      dispatch(toggleNotification(true, constants.notification_type.error, '', err, false))
    }
    else if (!req.length && (getState().global.isPasswordMatched)) {
      if (currentSignUpSite.acceptsReferrals && parseInt(currentSignUpSite.acceptsReferrals)) {
        let errorMsg = []
        if (currentSignUpSite.category != 'Clinic') {
          errorMsg.push(messages.clinic_validation)
        }
        let resultFields = referalReqFields.filter(rq => !currentSignUpSite[rq])
        if (resultFields && resultFields.length) {
          resultFields.map(rf => {
            errorMsg.push(`${reqFieldText[rf]} ${labels.required_label}`)
          })
          dispatch(toggleNotification(true, constants.notification_type.error, '', errorMsg, false))
          dispatch(toggleLoading(false))
          window.scrollTo(0, 0)
        }
      }
      if (currentSignUpSite.website && currentSignUpSite.website.indexOf('http') == -1 && currentSignUpSite.website.indexOf('https') == -1) {
        let validate = validateWebsite(currentSignUpSite.website)
        if (validate) {
          currentSignUpSite.website = `http://${currentSignUpSite.website}`
        }
      }
      let createUserDTO = currentSignUpUser;
      let createSiteDTO = currentSignUpSite;
      let signupParams = { createUserDTO, createSiteDTO };
      return signupParams;
    }
  }
}

export function removeToggleNotification(eve) {
  return (dispatch, getState) => {
    let isNotify = getState().global.isNotify ? getState().global.isNotify : false;
    let count = getState().global.loginCharCount ? getState().global.loginCharCount : 0;
    dispatch({
      type: SET_PROPS,
      payload: {
        loginCharCount: count + 1
      }
    })
    if (isNotify && count > 3) {
      dispatch(toggleNotification(false));
    }
  }
}

export function editSignupPaymentDetails(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let key = eve.target.id
    let value = eve.target.value
    if (eve.target.type == 'checkbox') {
      value = eve.target.checked ? 1 : 0
    }
    let currentSignupPaymentDetails = getState() && getState().global && getState().global.currentSignupPaymentDetails ? { ...getState().global.currentSignupPaymentDetails, [key]: value } : {}
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignupPaymentDetails
      }
    })

  }
}

export function paymentDetailsSubmissionError(err) {
  let errorMsg = []
  errorMsg.push(err);
  return (dispatch, getState) => {
    dispatch(toggleNotification(true, constants.notification_type.error, '', errorMsg, false));
  }
}


export function toggleSignupRendering(pageNo) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false));
    let toggleNumber = getState() && getState().global && getState().global.toggleSignupRender && getState().global.toggleSignupRender == 1 ? 2 : 1;
    if (pageNo) {
      toggleNumber = 1;
    }
    window.scrollTo(0, 0)
    dispatch({
      type: SET_PROPS,
      payload: { toggleSignupRender: toggleNumber }
    })
  }
}

export function registerSignUpUser(history, token) {
  return (dispatch, getState) => {
    let currentSignUpUser = getState() && getState().global && getState().global.currentSignUpUser ? getState().global.currentSignUpUser : {}
    let currentSignUpSite = getState().global.currentSignUpSite ? getState().global.currentSignUpSite : {}
    let currentSignupPaymentDetails = getState().global.currentSignupPaymentDetails ? getState().global.currentSignupPaymentDetails : {};
    currentSignupPaymentDetails = { ...currentSignupPaymentDetails, tokenId: token.id };
    let createUserDTO = currentSignUpUser;
    createUserDTO['isPrimaryContact'] = 1;
    createUserDTO['role'] = 'caregiver';
    let createSiteDTO = currentSignUpSite;
    let recurlyDTO = currentSignupPaymentDetails;
    let signupParams = { createUserDTO, createSiteDTO, recurlyDTO };

    if (currentSignUpUser.userSurvey == "") {
      currentSignUpUser.userSurvey = "Others";
    }

    if (currentSignupPaymentDetails.country == '0')
      delete currentSignupPaymentDetails.country

    for (let property in currentSignupPaymentDetails) {
      if (currentSignupPaymentDetails[property] == "")
        delete currentSignupPaymentDetails[property];
    }

    // delete currentSignUpSite.bloodAndUrinePanels;
    // delete currentSignUpSite.optionalBiomarkerPanels;

    for (let property in currentSignUpSite) {
      if (currentSignUpSite[property] == "")
        delete currentSignUpSite[property];
    }
    for (let property in currentSignUpUser) {
      if (currentSignUpUser[property] == "" && property != 'isProvider')
        delete currentSignUpUser[property];
    }
  
    currentSignUpSite.noumenonCodes && currentSignUpSite.noumenonCodes.forEach(noumenon => {
      noumenon.endDate = noumenon.endDate ? moment(noumenon.endDate).format('YYYY-MM-DD HH:mm:ss') : "";
      noumenon.startDate = noumenon.startDate ? moment(noumenon.startDate).format('YYYY-MM-DD HH:mm:ss') : "";
    });
    if (currentSignUpSite.noumenonCodes)
      currentSignUpSite.noumenonCodes = currentSignUpSite.noumenonCodes.filter(noumenon => noumenon.code);

    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: currentSignUpSite,
        currentSignUpUser: currentSignUpUser,
        currentSignupPaymentDetails: currentSignupPaymentDetails
      }
    })

    if (currentSignupPaymentDetails["country"] == undefined || currentSignupPaymentDetails["country"] == "") {
      let errorMsg = []
      errorMsg.push(messages.country_required);
      dispatch(toggleLoading(false));
      dispatch(toggleNotification(true, constants.notification_type.error, '', errorMsg, false));
    }
    else {
      // dispatch(toggleLoading(true));
      promisesList.push(axios.post(API_URL.SIGNUP_USER, { ...signupParams }).then(response => {
        // dispatch(setLoggedInUser())
        setTimeout(() => {
          dispatch(handleLoading());

          history.push('/login');
          dispatch(toggleNotification(true, constants.notification_type.success, '',
            [messages.user_add_success_signup]
            , false, 5000))

        }, 2000);
        dispatch({
          type: SET_PROPS,
          payload: {
            showCreditsCaution: false
          }
        })
      }).catch(error => {
        dispatch(handleErrors(error))
      }))
    }
  }
}

export function deleteCurrentSignupPaymentDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: { currentSignupPaymentDetails: {} }
    })
  }
}


export function editBloodAndUrinePanels(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let currentSignUpSite = getState().global.currentSignUpSite ? getState().global.currentSignUpSite : {}
    let value = eve && eve.length ? eve.map(e => constants.map_defaultpanels_names_to_values[e.name] ? constants.map_defaultpanels_names_to_values[e.name] : e.name) : []
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: Object.assign({}, currentSignUpSite, { bloodAndUrinePanels: value && value.length ? value.join(',') : '' })
      }
    })
  }
}


export function editOptionalBiomarkerPanels(eve) {
  return (dispatch, getState) => {
    dispatch(toggleNotification(false))
    let currentSignUpSite = getState().global.currentSignUpSite ? getState().global.currentSignUpSite : {}
    let value = eve && eve.length ? eve.map(e => constants.map_defaultpanels_names_to_values[e.name] ? constants.map_defaultpanels_names_to_values[e.name] : e.name) : []
    dispatch({
      type: SET_PROPS,
      payload: {
        currentSignUpSite: Object.assign({}, currentSignUpSite, { optionalBiomarkerPanels: value && value.length ? value.join(',') : '' })
      }
    })
  }
}


//action handlers
const ACTION_HANDLERS = {
  [SET_PROPS]: (state, action) => {
    return Object.assign({}, state, { ...action.payload })
  },
  [TOGGLE_HEADER_COLLAPSE]: (state, action) => {
    return Object.assign({}, state, { isHeaderCollapseOpen: (action.payload != null && action.payload != undefined) ? action.payload : !state.isHeaderCollapseOpen })
  },
  [TOGGLE_LOADING]: (state, action) => {
    return Object.assign({}, state, { isLoading: action.payload })
  },
  [TOGGLE_REPORT_LOADING]: (state, action) => {
    return Object.assign({}, state, { isReportLoading: action.payload })
  },
  [TOGGLE_NOTIFICATION]: (state, action) => {
    let notifications = Object.assign({}, state.notifications, { ...action.payload })
    return Object.assign({}, state, { notifications, isNotify: action.payload.status })
  },
  [CLEAR_GLOBAL]: (state, action) => {
    return Object.assign({}, initialState)
  }
};
//default state
const initialState = {
  isAuthenticated: localStorage.getItem(constants.accessToken) ? true : false,
  isLoading: false,
  isReportLoading: false,
  isHeaderCollapseOpen: false,
  loggedInUser: localStorage.getItem(constants.role) ? { role: constants.logged_roles[localStorage.getItem(constants.role)] } : {},
  notifications: {},
  dropdownOpen: '',
  sentResetEmail: false,
  showCreditsCaution: false,
  isRefreshed: false,
  linkexpired: null,
  isParentContainer: false,
  currentSignUpUser: { active: 1 },
  isPasswordMatched: true,
  currentSignUpSite: {},
  loginCharCount: 0,
  currentSignupPaymentDetails: {},
  toggleSignupRender: 1,
  noumenonNameList: []
};
//reducer
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export const actionCreators = {
  logOut,
  getAuthStatus,
  headerCollapseToggle,
  navigatePatients,
  headerNavigation,
  toggleHeaderNavigation,
  toggleNotification,
  handleErrors,
  setHistory,
  clearSentMailStatus,
  autoRefreshToken,
  setLoggedInUser,
  setCreditsCaution,
  handleStatusCodes,
  clearUsermail,
  validateResetPassword,
  setPageTitle,
  setBreadCrumbs,
  hideHeaderSkeleton,
  noHeaderLoading,
  handleLoading,
  makeParentContainer,
  checkAccessToken,
  setModelWidth,
  toggleToaster,
  setAllPatientDetails,
  downloadDocument,
  ignoreToaster,
  editSignUpUser,
  resetSignUpPassword,
  editSignUpPassword,
  editSignUpSite,
  setCurrentSignUpSite,
  logoUploadedInSignUp,
  removeLogoPicInSignUp,
  removeSignUpUserPic,
  uploadSignUpUserPic,
  saveSignUpUser,
  setCurrentSignUpUser,
  removeToggleNotification,
  editSignupPaymentDetails,
  paymentDetailsSubmissionError,
  toggleSignupRendering,
  registerSignUpUser,
  deleteCurrentSignupPaymentDetails,
  editBloodAndUrinePanels,
  editOptionalBiomarkerPanels,
  toggleReportLoading,
  GetAllNoumenonNames,
  setNoumenonFields,
  addNoumenonFields,
  removeNoumenonFields,
  editNoumenon,
  getAuth,
  sendOTP,
  validateOTP,
  getQRCode,
  handleSuceessDone
}